import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect, } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Popover, Tooltip } from 'antd';
import { useSession } from 'components/Authenticated';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import { formatMediaTime } from 'libs/format-number';
import { CursorPlayIcon, PauseIcon, PlayAgainIcon } from 'components/CustomIcons';
import { CaretRightOutlined, SettingOutlined, StopOutlined } from '@ant-design/icons';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import ResponsiveTooltip from 'components/ResponsiveTooltip';
import { usePlaybackContext } from '../WithMediaPlayback';
import { PlaybackEvents } from '../MediaPlayer/playback';
import Seekbar, { DEFAULT_KNOB_POS, KNOB_WIDTH } from './Seekbar';
import './style.less';
import PlayerSettings from './PlayerSettings';
let draggingKnob = false;
let resumePlaybackAfterDrag = false;
const MediaControls = ({ mediaStart, caretTimeRef, isWaveVisible, onWaveVisibilityChange, captions, }) => {
    const { playback, playerState } = usePlaybackContext();
    const { session } = useSession();
    const [playing, setPlaying] = useState(playback.playing);
    const seekBarRef = useRef(null);
    const currentTimeRef = useRef(null);
    const durationRef = useRef(null);
    const seekBarTimeRef = useRef(null);
    const { tutorialGoNext } = useBeeyTutorial();
    const { screenSize } = useAppContext();
    const isMobile = screenSize.width <= screenBreakpoints.sm;
    const startDatetime = session.login.user.settings.showMediaDate
        ? mediaStart
        : null;
    const getPlayerKnobPos = (time) => {
        const seekBarRect = seekBarRef.current ? seekBarRef.current.getBoundingClientRect() : null;
        const playerKnobPos = seekBarRect && playback.duration !== 0
            ? (time / playback.duration) * seekBarRect.width - (KNOB_WIDTH / 2) : DEFAULT_KNOB_POS;
        return { playerKnobPos, seekBarRect };
    };
    useEffect(() => {
        const handlePlayingUpdate = (newPlaying) => setPlaying(newPlaying);
        const handleDurationUpdate = (newDuration) => {
            if (durationRef.current === null)
                return;
            durationRef.current.textContent = formatMediaTime(newDuration, startDatetime);
        };
        const handleTimeUpdate = (time) => {
            if (currentTimeRef.current === null || seekBarRef.current === null)
                return;
            const seekBarWidth = playback.duration !== 0 ? (time / playback.duration) * 100 : 0;
            seekBarRef.current.children[0].style.width = `${seekBarWidth}%`;
            seekBarRef.current.children[1].style.marginLeft = `${getPlayerKnobPos(time).playerKnobPos}px`;
            currentTimeRef.current.textContent = formatMediaTime(time, startDatetime);
        };
        playback.addEventListener(PlaybackEvents.PlayingUpdate, handlePlayingUpdate);
        playback.addEventListener(PlaybackEvents.DurationUpdate, handleDurationUpdate);
        playback.addEventListener(PlaybackEvents.TimeUpdate, handleTimeUpdate);
        // NOTE: Playback duration sometimes loads before the listener is added here.
        // handle duration explicitly so that we display correct duration even
        // if the event was not handled.
        handleDurationUpdate(playback.duration);
        return () => {
            playback.removeEventListener(PlaybackEvents.PlayingUpdate, handlePlayingUpdate);
            playback.removeEventListener(PlaybackEvents.DurationUpdate, handleDurationUpdate);
            playback.removeEventListener(PlaybackEvents.TimeUpdate, handleTimeUpdate);
        };
    }, []);
    const handlePlayFromCaret = () => {
        if (caretTimeRef.current === null)
            return;
        playback.playFromTime(caretTimeRef.current);
    };
    const handleTogglePlay = () => {
        if (session.login.user.settings.isAudioDelayed && !playback.playing) {
            playback.delayAudio(session.login.user.settings.audioDelay);
        }
        else {
            playback.togglePlay();
        }
    };
    const getTimeFromX = (pointerX) => {
        if (seekBarRef.current === null)
            return 0;
        const rect = seekBarRef.current.getBoundingClientRect();
        const relative = (pointerX - rect.left) / rect.width;
        let time = relative * playback.duration;
        // edge cases - click and move outside rect of playback
        if (time <= 0) {
            time = 0;
        }
        else if (time >= playback.duration) {
            time = playback.duration;
        }
        return time;
    };
    const handlePointerLeave = () => {
        if (seekBarTimeRef.current === null)
            return;
        seekBarTimeRef.current.style.display = 'none';
    };
    const displaySeekedTime = (time) => {
        if (seekBarTimeRef.current === null)
            return;
        seekBarTimeRef.current.textContent = formatMediaTime(time, startDatetime);
        seekBarTimeRef.current.style.display = 'initial';
        const playerPositions = getPlayerKnobPos(time);
        const seekBarTimeRect = seekBarTimeRef.current.getBoundingClientRect();
        // edge cases - current time should be displayed in the range of seekbar
        if (playerPositions.seekBarRect !== null) {
            if (playerPositions.playerKnobPos
                >= playerPositions.seekBarRect.width - seekBarTimeRect.width) {
                seekBarTimeRef.current.style.marginLeft = `${playerPositions.seekBarRect.width - seekBarTimeRect.width}px`;
            }
            else if (playerPositions.playerKnobPos <= 0) {
                seekBarTimeRef.current.style.marginLeft = '0px';
            }
            else {
                seekBarTimeRef.current.style.marginLeft = `${playerPositions.playerKnobPos}px`;
            }
        }
    };
    const handleMoveEvent = (event) => {
        const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
        const time = getTimeFromX(clientX);
        displaySeekedTime(time);
        if (draggingKnob) {
            // NOTE: Seeking while dragging causes problems with long videos.
            playback.scrubTo(time);
        }
    };
    const handlePointerUp = (event) => {
        const time = getTimeFromX(event.clientX);
        playback.seekTo(time);
        draggingKnob = false;
        event.target.releasePointerCapture(event.pointerId);
        if (resumePlaybackAfterDrag) {
            playback.play();
        }
    };
    const handlePointerDown = (event) => {
        event.target.setPointerCapture(event.pointerId);
        draggingKnob = true;
        resumePlaybackAfterDrag = playback.playing;
        playback.pause();
    };
    const message = (() => {
        if (playerState.status === 'loading') {
            return txt('loading');
        }
        if (playerState.status === 'disabled') {
            return txt('recordingNotPlayable');
        }
        return null;
    })();
    const playerIsReady = playerState.status === 'ready';
    return (_jsxs(Tooltip, { title: message, placement: "top", overlayStyle: playerIsReady ? { display: 'none' } : {}, children: [_jsx("div", { className: clsx('media-controls', { 'media-controls--loading': playerState.status === 'loading' }, { 'media-controls--disabled': playerState.status === 'disabled' }), children: _jsxs("div", { className: "media-controls__layout", children: [_jsxs("div", { className: "media-controls__buttons", children: [captions.enabled ? (_jsx(ResponsiveTooltip, { title: playerIsReady ? `${txt('replayCaption')} (Alt+P)` : false, children: _jsx("div", { children: _jsx("button", { type: "button", className: "media-controls__btn media-controls__btn--caret-play", onClick: captions.handleReplayCaption, disabled: !playerIsReady, children: _jsx(PlayAgainIcon, {}) }) }) })) : null, _jsx(ResponsiveTooltip, { title: playerIsReady ? txt('play') : false, children: _jsx("div", { children: _jsx("button", { type: "button", className: "media-controls__btn media-controls__btn--play-pause", onClick: handleTogglePlay, disabled: !playerIsReady, children: playing ? (_jsx(PauseIcon, { className: "pause-icon" })) : (_jsx(CaretRightOutlined, { className: "play-icon" })) }) }) }), _jsx(ResponsiveTooltip, { title: playerIsReady ? txt('playCursor') : false, children: _jsx("div", { children: _jsx("button", { type: "button", className: "media-controls__btn media-controls__btn--caret-play beey-tutorial-step-play", onClick: () => {
                                                handlePlayFromCaret();
                                                tutorialGoNext();
                                            }, disabled: !playerIsReady, children: _jsx(CursorPlayIcon, { className: "cursor-play-icon" }) }) }) })] }), _jsxs("div", { className: "seektime", children: [!isMobile ? (_jsx(Seekbar, { playerIsReady: playerIsReady, playerStateStatus: playerState.status, onHandlePointerUp: handlePointerUp, onHandlePointerDown: handlePointerDown, onHandlePointerMove: handleMoveEvent, onHandlePointerLeave: handlePointerLeave, seekBarRef: seekBarRef, seekBarTimeRef: seekBarTimeRef })) : null, _jsxs("div", { className: "media-controls__time", children: [_jsxs("div", { className: "timer-total", children: [session.login.user.settings.showMediaDate && (_jsxs("span", { children: [startDatetime === null
                                                            ? (_jsx(ResponsiveTooltip, { title: txt('noMediaDate'), placement: "bottom", children: _jsx(StopOutlined, {}) }))
                                                            : dayjs(startDatetime).format('D.M.YYYY'), "\u00A0"] })), _jsx("span", { id: "timerTotal", ref: currentTimeRef, children: formatMediaTime(playback.time, startDatetime) })] }), isMobile ? (_jsx("span", { children: "-" })) : null, _jsx("div", { className: "timer-total", id: "timerTotal", ref: durationRef, children: formatMediaTime(playback.duration, startDatetime) })] })] }), _jsx(Popover, { content: (_jsx(PlayerSettings, { isWaveVisible: isWaveVisible, onWaveVisibilityChange: onWaveVisibilityChange, isCaptionMode: captions.enabled })), placement: "leftBottom", trigger: "hover", arrow: false, children: _jsx("span", { children: _jsx(SettingOutlined, { className: "media-controls__settings" }) }) })] }) }), isMobile ? (_jsx(Seekbar, { playerIsReady: playerIsReady, playerStateStatus: playerState.status, onHandlePointerUp: handlePointerUp, onHandlePointerDown: handlePointerDown, onHandleTouchMove: handleMoveEvent, onHandlePointerLeave: handlePointerLeave, seekBarRef: seekBarRef, seekBarTimeRef: seekBarTimeRef })) : null] }));
};
export default MediaControls;
