import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSession } from 'components/Authenticated';
import { Form, Select, AutoComplete, App, } from 'antd';
import { fetchSpeakerRoleSuggest } from 'api/speaker-api';
import { txt } from 'libs/i18n';
import { filterOption } from 'libs/filterOption';
const SpeakerRole = ({ direction = 'ltr' }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const { teamSettings } = session;
    const [roles, setRoles] = useState({
        isSelectionStrict: false,
        data: [],
    });
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const isSelectionStrict = teamSettings.isStrictRoleSelection;
                const data = await fetchSpeakerRoleSuggest(session.connection);
                setRoles({ data, isSelectionStrict });
            }
            catch (error) {
                global.logger.error('Failed to get speakers role from database', {}, error);
                void message.error(txt('failedToFetchRole'), 2);
            }
        };
        void fetchRoles();
    }, []);
    const options = roles.data.map((item) => ({
        key: item,
        value: item,
        label: item,
        children: item,
    }));
    return (_jsx(Form.Item, { name: "role", label: txt('role'), dependencies: ['firstName, lastName'], rules: [
            ({ getFieldValue }) => ({
                required: roles.isSelectionStrict,
                validator(_, value) {
                    const isNameEmpty = getFieldValue('lastName').trim() === '' && getFieldValue('firstName').trim() === '';
                    if (roles.isSelectionStrict) {
                        if (!roles.data.includes(value.trim())) {
                            return Promise.reject(txt('chooseRole'));
                        }
                        if (isNameEmpty) {
                            return Promise.reject(txt('addName'));
                        }
                    }
                    return Promise.resolve();
                },
            }),
        ], children: roles.isSelectionStrict ? (_jsx(Select, { showSearch: true, filterOption: filterOption, options: options, className: direction })) : (_jsx(AutoComplete, { placeholder: txt('roleExample'), filterOption: filterOption, options: options, className: direction })) }));
};
export default SpeakerRole;
