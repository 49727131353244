import Quill from 'quill';
import { txt } from 'libs/i18n';
export default class Sections {
    constructor(editorController, headingsNamesMetadata) {
        this.handleSectionNameSelected = (sectionValues, clickTarget) => {
            if (clickTarget === null) {
                this.createSectionBeforeSelection(sectionValues);
            }
            else {
                this.replaceSectionName(sectionValues, clickTarget);
            }
        };
        this.getSectionNodes = () => {
            if (this.editorController.rootElement === null) {
                throw Error('editor is not mounted');
            }
            const nodes = this.editorController.rootElement.getElementsByTagName('h2');
            return nodes;
        };
        this.createSectionBeforeSelection = (sectionValues) => {
            const selection = this.editorController.forceGetQuillSelection();
            let lineStartIndex = this.editorController.getLineStart(selection.index);
            if (lineStartIndex === 0) {
                this.editorController.insertText(0, '\n');
                lineStartIndex = 1;
            }
            const previousFormat = this.editorController.getLineFormat(lineStartIndex - 1);
            if (previousFormat.speaker !== undefined) {
                lineStartIndex = this.editorController.getLineStart(lineStartIndex - 1);
            }
            const wasLineEmpty = this.editorController.getText(lineStartIndex, 1) === '\n';
            this.editorController.insertTextWithFormat(lineStartIndex, `${sectionValues.name}\n`, {
                section: { tags: [], id: sectionValues.id },
                uneditable: true,
                warning: this.getSectionWarning(sectionValues.name),
            }, 'user');
            if (wasLineEmpty) {
                // NOTE: remove the duplicate line end.
                this.editorController.deleteText(lineStartIndex + sectionValues.name.length + 1, 1);
            }
        };
        this.replaceSectionName = (sectionValues, clickTarget) => {
            const blot = Quill.find(clickTarget);
            const from = this.editorController.getIndex(blot);
            const to = this.editorController.getNextNewLineIndex(from);
            if (to === null) {
                global.logger.error('cannot add section name, to is null');
                return;
            }
            const previousFormatOfSection = this.editorController.getLineFormat(from).section;
            this.editorController.deleteText(from, to - from);
            this.editorController.insertText(from, sectionValues.name);
            const formatValue = Object.assign(Object.assign({}, previousFormatOfSection), { id: sectionValues.id });
            this.editorController.formatLine(from, sectionValues.name.length, 'section', formatValue);
            this.editorController.formatText(from, sectionValues.name.length, { uneditable: true, warning: this.getSectionWarning(sectionValues.name) }, 'user');
        };
        this.getSectionWarning = (name) => {
            const isSectionNameMissing = this.editorController.sectionNamesList !== null
                && !this.editorController.sectionNamesList.options.some((option) => option.name === name);
            return isSectionNameMissing ? 'section-name-missing' : undefined;
        };
        this.formatLineAsSection = () => {
            const range = this.editorController.forceGetQuillSelection();
            const currentFormat = this.editorController.getLineFormat(range.index);
            const lineEnd = this.editorController.getNextNewLineIndex(range.index + range.length);
            if (currentFormat.section === undefined && lineEnd === this.editorController.getLength() - 1) {
                // NOTE: This is an edge case that fixes a bug: If user formats last document line as section
                // with summaries enabled, the formatting is removed. This is because formatting last line
                // does not work correctly in quill.
                this.editorController.insertText(lineEnd, '\n');
            }
            if (currentFormat.summary !== undefined) {
                void this.editorController.onMessage('info', txt('headingPlacementError'));
                return;
            }
            if (currentFormat.section !== undefined) {
                this.editorController.formatLine(range.index, range.length, 'section', false, 'user', true);
            }
            else {
                this.editorController.formatLine(range.index, range.length, 'section', { tags: [], id: null }, 'user', true);
            }
            // NOTE: We have to trigger document widget change manually
            // because index does not change, only formatting.
            this.editorController.emitWidgetChangeEvent();
        };
        this.editorController = editorController;
        this.headingsNamesMetadata = headingsNamesMetadata;
    }
    insertSection() {
        if (this.headingsNamesMetadata !== null) {
            this.editorController.onSectionHeadingClicked();
        }
        else {
            this.formatLineAsSection();
        }
    }
    removeSection(sectionNode) {
        if (this.editorController.quill === undefined) {
            throw new Error('quill is not defined');
        }
        const sectionBlot = Quill.find(sectionNode);
        const index = this.editorController.getIndex(sectionBlot);
        const lineEndIndex = this.editorController.getNextNewLineIndex(index);
        if (lineEndIndex === null)
            return;
        this.editorController.deleteText(index, lineEndIndex + 1 - index, 'user');
    }
}
