import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { App, Card, Popconfirm, Tooltip, Flex, } from 'antd';
import { DeleteOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import AddButton from 'components/AddButton';
import * as Announcements from 'api/announcements';
import { useSession } from 'components/Authenticated';
import dayjs from 'dayjs';
import { renderMarkdown } from 'libs/markdown';
import { txt } from 'libs/i18n';
import { fetchAllTeamOwnerUsers } from 'api/admin/users-api';
import IconButton from 'components/IconButton';
import { nanoid } from 'nanoid';
import NewAnnouncementModal from './NewAnnouncementModal';
import styles from './style.module.less';
const formatDate = (date) => dayjs(date).format('DD. MM. YYYY HH:mm');
const ANNOUNC_TABLE_ID = 'announc-table'; // Must be unique across all tables in Beey!!
const AnnouncementSubpage = () => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [announcementModalVisible, setAnnouncementModalVisible] = useState(false);
    const [announcementsList, setAnnouncementsList] = useState('loading');
    const [announcementsShowFull, setAnnouncementsShowFull] = useState([]);
    const [teamsOptions, setTeamsOptions] = useState('loading');
    const tableView = useTableView(ANNOUNC_TABLE_ID);
    useEffect(() => {
        const fetchTeams = async () => {
            const teams = await fetchAllTeamOwnerUsers(session.connection);
            setTeamsOptions(teams.map((team) => ({
                label: team.email,
                value: Number(team.teamId),
                key: Number(team.teamId),
            })));
        };
        void fetchTeams();
    }, []);
    const fetchAnnouncementList = async () => {
        const { currentPage, pageSize } = tableView.getViewParams();
        try {
            const announcements = await Announcements.fetchAnnouncementsAdmin(session.connection, '', '', {
                skip: pageSize * (currentPage - 1),
                count: pageSize,
            });
            const list = announcements.list.map((item) => ({
                key: item.id,
                dateFrom: `${formatDate(item.from)}`,
                dateTo: `${formatDate(item.to)}`,
                localizedAnnouncements: item.localizations,
                id: item.id,
                teamIds: item.teamIds,
                managementGroupId: item.managementGroupId,
            }));
            setAnnouncementsList({
                announcements: list,
                totalCount: announcements.totalCount,
            });
        }
        catch (error) {
            global.logger.error('Fetching list of announcements failed', {}, error);
        }
        setAnnouncementModalVisible(false);
    };
    const handleDelete = async (key) => {
        const { currentPage } = tableView.getViewParams();
        try {
            await Announcements.deleteAnnouncement(session.connection, key);
            if (currentPage !== 1
                && announcementsList !== 'loading'
                && announcementsList.announcements.length === 1) {
                tableView.setCurrentPage(currentPage - 1);
            }
            void message.success(txt('successfullyDeleted'));
        }
        catch (error) {
            global.logger.error('Deleting announcements failed', {}, error);
            void message.error(txt('failedToDelete'));
        }
        void fetchAnnouncementList();
    };
    const handleOpenOtherLanguages = (id) => {
        setAnnouncementsShowFull((prevShowFullIds) => {
            if (prevShowFullIds.includes(id)) {
                return prevShowFullIds.filter((showFullId) => showFullId !== id);
            }
            return [...prevShowFullIds, id];
        });
    };
    const columns = [
        {
            title: txt('announcement'),
            width: '40%',
            dataIndex: 'localizedAnnouncements',
            render: (announcements, record) => {
                const [[firstKey, firstValue], ...restAnnouncements] = Object.entries(announcements);
                return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.announcement, children: [_jsx("p", { children: _jsxs("strong", { children: [firstKey.substring(3, 5), ":"] }) }), _jsx("span", { dangerouslySetInnerHTML: { __html: renderMarkdown(firstValue) } })] }, firstKey), restAnnouncements.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", { children: [_jsx(IconButton, { onClick: () => handleOpenOtherLanguages(record.id), className: styles.icon, children: announcementsShowFull.includes(record.id)
                                                ? _jsx(DownOutlined, {})
                                                : _jsx(RightOutlined, {}) }), _jsx("span", { className: styles.iconText, children: txt('otherLanguages') })] }), announcementsShowFull.includes(record.id) ? (restAnnouncements.map(([key, value]) => (_jsxs("div", { className: styles.announcement, children: [_jsx("p", { children: _jsxs("strong", { children: [key.substring(3, 5), ":"] }) }), _jsx("span", { dangerouslySetInnerHTML: { __html: renderMarkdown(value) } })] }, key)))) : null] })) : null] }));
            },
        },
        {
            title: txt('displayedTo'),
            align: 'center',
            render: (entry) => {
                const teamOwners = teamsOptions !== 'loading' && entry.teamIds !== null
                    ? teamsOptions
                        .filter((option) => { var _a; return (_a = entry.teamIds) === null || _a === void 0 ? void 0 : _a.includes(Number(option.value)); })
                        .map((option) => option.label)
                    : [];
                if (teamOwners.length > 0) {
                    return (_jsxs(Flex, { vertical: true, children: [_jsxs("span", { children: [txt('teams'), ":"] }), teamOwners.map((teamOwner) => (_jsx("span", { children: teamOwner }, nanoid(5))))] }));
                }
                if (entry.managementGroupId !== null && session.login.hasClaim('admin:superadmin')) {
                    return (_jsxs(_Fragment, { children: [_jsxs("span", { children: [txt('managementGroup'), ":"] }), _jsx("span", { children: entry.managementGroupId })] }));
                }
                return (_jsx("span", { children: txt('everyone') }));
            },
        },
        {
            title: txt('from'),
            align: 'center',
            dataIndex: 'dateFrom',
        },
        {
            title: txt('to'),
            align: 'center',
            dataIndex: 'dateTo',
        },
        {
            dataIndex: 'key',
            render: (key) => (_jsx(Popconfirm, { title: txt('deleteAnnouncement'), okText: txt('yesDelete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => { void handleDelete(key); }, cancelText: txt('cancel'), children: _jsx(Tooltip, { title: txt('delete'), children: _jsx(IconButton, { children: _jsx(DeleteOutlined, {}) }) }) })),
        },
    ];
    const toolbar = (_jsx("div", { className: styles.toolbar, children: _jsx("div", { children: _jsx(AddButton, { title: txt('newAnnouncement'), onClick: () => setAnnouncementModalVisible(true) }) }) }));
    return (_jsxs(Card, { bordered: false, children: [toolbar, _jsx(ResponsiveTable, { tableView: tableView, rowKey: (row) => row.key, onViewChange: () => { void fetchAnnouncementList(); }, dataSource: announcementsList === 'loading' ? [] : announcementsList.announcements, recordCount: announcementsList === 'loading' ? 0 : announcementsList.totalCount, columns: columns, loading: announcementsList === 'loading' }), _jsx(NewAnnouncementModal, { onOk: () => { void fetchAnnouncementList(); }, onCancel: () => setAnnouncementModalVisible(false), visible: announcementModalVisible, teamsOptions: teamsOptions })] }));
};
export default AnnouncementSubpage;
