import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Radio, Spin, } from 'antd';
import { txt } from 'libs/i18n';
import { ArrowUpOutlined, CreditCardOutlined } from '@ant-design/icons';
import { SparkleIcon } from 'components/CustomIcons';
import styles from './style.module.less';
import PlanCard from './PlanCard';
const PlansOverview = ({ currency, isSubscribed, plan, onGetStripeCustomerPortal, isCanceled, products, onSetPlanInterval, onCreateOrder, }) => {
    const [form] = Form.useForm();
    const plansToDisplay = () => ((products !== 'loading' && plan !== 'loading')
        ? products.filter((product) => product.interval === plan.interval || product.interval === '' || product.interval === 'custom')
        : []);
    return (_jsxs(_Fragment, { children: [_jsxs("h1", { className: styles.title, children: [_jsx(CreditCardOutlined, {}), ' ', txt('plansAndPricing')] }), plansToDisplay().length > 1 ? (_jsxs("div", { className: styles.plans, children: [_jsx("div", { className: styles.switchWrapper, children: _jsx(Form, { form: form, children: _jsx(Form.Item, { name: "interval", children: _jsxs("div", { children: [_jsxs(Radio.Group, { value: plan !== 'loading' ? plan.interval : 'year', buttonStyle: "solid", size: "small", className: styles.switch, onChange: (e) => onSetPlanInterval(e.target.value), children: [_jsx(Radio.Button, { value: "month", children: txt('monthly') }), _jsx(Radio.Button, { value: "year", children: txt('yearly') })] }), plan !== 'loading' ? (_jsxs("span", { className: styles.info, children: [_jsx(ArrowUpOutlined, { rotate: 70, className: styles.arrowIcon }), txt('twoFreeMonths'), _jsx(SparkleIcon, { className: styles.sparkleIcon })] })) : null] }) }) }) }), _jsxs("div", { className: styles.cards, children: [plansToDisplay().map((product) => (_jsx(PlanCard, { lookupKey: product.lookupKey, onCreateOrder: onCreateOrder, isSubscribed: isSubscribed, plan: plan !== 'loading' ? plan.planDetails : null, interval: product.interval, currency: currency, price: product.pricesForCurrencies[currency.toLocaleLowerCase()].priceWithTax, onGetStripeCustomerPortal: (landingPage) => onGetStripeCustomerPortal(landingPage, product.priceId), isCanceled: isCanceled, quantity: product.productMetadata.orderMinQuantity, productName: product.productName }, product.lookupKey))), _jsx(PlanCard, { lookupKey: "enterprise", onCreateOrder: onCreateOrder, isSubscribed: isSubscribed, plan: plan !== 'loading' ? plan.planDetails : null, interval: "custom", currency: currency, price: 0, onGetStripeCustomerPortal: (landingPage) => onGetStripeCustomerPortal(landingPage, null), isCanceled: isCanceled, quantity: "", productName: "Enterprise" })] })] })) : _jsx(Spin, {})] }));
};
export default PlansOverview;
