import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState, useEffect, useMemo, createContext, } from 'react';
import Joyride, { STATUS, } from 'react-joyride';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
const TUTORIAL_TRANSITION_TIMEOUT = 400;
const tutorialStates = ['running', 'last-step', 'completed'];
const isTutorialState = (value) => tutorialStates.find((state) => state === value) !== undefined;
export const createTutorial = (tutorialName) => {
    const TutorialContext = createContext({});
    const useTutorial = () => useContext(TutorialContext);
    const storageKey = `${tutorialName}TutorialState`;
    const Tutorial = ({ children, tutorialSteps }) => {
        const { updateUserSettings } = useSession();
        const [tooltipState, setTooltipState] = useState({
            phase: 'hidden',
            currentStep: 0,
        });
        const [tutorialProjectId, setTutorialProjectId] = useState(0);
        const retrieveTutorialState = () => {
            const value = window.localStorage.getItem(storageKey);
            if (value === null) {
                return 'completed';
            }
            if (isTutorialState(value)) {
                return value;
            }
            return 'completed';
        };
        const storeTutorialState = (state) => {
            window.localStorage.setItem(storageKey, state);
        };
        useEffect(() => {
            if (retrieveTutorialState() === 'running' && tooltipState.phase === 'hidden') {
                storeTutorialState('completed');
            }
        }, []);
        const tutorialStart = () => {
            if (tooltipState.phase === 'hidden') {
                storeTutorialState('running');
                setTimeout(() => {
                    setTooltipState(Object.assign(Object.assign({}, tooltipState), { phase: 'visible' }));
                }, TUTORIAL_TRANSITION_TIMEOUT);
            }
        };
        const tutorialContinue = () => {
            if (tooltipState.phase !== 'hidden') {
                setTimeout(() => {
                    setTooltipState(Object.assign(Object.assign({}, tooltipState), { phase: 'visible' }));
                }, TUTORIAL_TRANSITION_TIMEOUT);
            }
        };
        const tutorialPrepareNext = () => {
            if (tooltipState.phase !== 'hidden') {
                setTooltipState({ phase: 'preparing', currentStep: tooltipState.currentStep + 1 });
            }
        };
        const tutorialGoNext = () => {
            if (tooltipState.phase !== 'hidden') {
                setTimeout(() => {
                    setTooltipState({ phase: 'visible', currentStep: tooltipState.currentStep + 1 });
                }, TUTORIAL_TRANSITION_TIMEOUT);
            }
        };
        const tutorialLastStep = () => {
            setTooltipState({ phase: 'hidden', currentStep: 0 });
            storeTutorialState('last-step');
        };
        const tutorialFinish = () => {
            setTooltipState({ phase: 'hidden', currentStep: 0 });
            storeTutorialState('completed');
        };
        const handleJoyrideCallback = ({ status }) => {
            if (status === STATUS.SKIPPED) {
                setTooltipState({ phase: 'hidden', currentStep: 0 });
                updateUserSettings({ startBeeyTutorial: false });
                storeTutorialState('completed');
            }
        };
        const value = useMemo(() => ({
            tutorialContinue,
            tutorialPrepareNext,
            tutorialGoNext,
            tutorialStart,
            tutorialFinish,
            tutorialLastStep,
            tutorialProjectId,
            setTutorialProjectId,
            currentStep: tooltipState.currentStep,
            retrieveTutorialState,
        }), [
            tutorialContinue,
            tutorialPrepareNext,
            tutorialGoNext,
            tutorialStart,
            tutorialFinish,
            tutorialLastStep,
            tutorialProjectId,
            setTutorialProjectId,
            tooltipState.currentStep,
            retrieveTutorialState,
        ]);
        return (_jsxs(_Fragment, { children: [retrieveTutorialState() !== 'completed'
                    ? (_jsx(Joyride, { run: tooltipState.phase === 'visible', stepIndex: tooltipState.currentStep, steps: tutorialSteps, callback: handleJoyrideCallback, disableCloseOnEsc: true, disableOverlayClose: true, hideCloseButton: true, hideBackButton: true, disableScrolling: true, disableScrollParentFix: true, locale: {
                            skip: txt('tutorialSkip'),
                        }, showSkipButton: true, spotlightClicks: true, spotlightPadding: 5, styles: {
                            tooltip: {
                                borderRadius: 15,
                                fontSize: 15,
                                color: '#444',
                                padding: '15px',
                                width: '300px',
                            },
                            tooltipContent: {
                                padding: '10px 0 0 0',
                            },
                            buttonNext: {
                                borderRadius: 30,
                            },
                            buttonBack: {
                                color: 'rgb(51, 51, 51)',
                                fontSize: 15,
                            },
                            buttonSkip: {
                                color: '#999',
                            },
                            options: {
                                zIndex: 1000,
                            },
                        } })) : null, _jsx(TutorialContext.Provider, { value: value, children: children })] }));
    };
    return [useTutorial, Tutorial, TutorialContext];
};
