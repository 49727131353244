import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getNodeProperty } from 'libs/quill-utils';
import { subtitlesDefaults } from 'api/settings/user-settings';
import SpeakerColorButton from '../SpeakerColorButton';
import SpeakerSign from '../SpeakerSign';
import SpeakerTimestamp from '../SpeakerTimestamp';
const SpeakerWidget = ({ position, widgetTrigger, disabled, isCaptionMode, editorController, }) => {
    const parentTriggerNode = widgetTrigger.parentElement;
    if (parentTriggerNode === null) {
        return null;
    }
    const getSpeakerColor = () => {
        var _a;
        // NOTE: The change in initial color (prop) enables to properly re-render
        // the SpeakerColorButton component, when the parent component is re-rendered.
        const backgroundColor = (_a = editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultColor;
        const speakerNode = widgetTrigger.parentElement;
        if (speakerNode !== null && backgroundColor !== undefined) {
            const captionColor = getNodeProperty(speakerNode, 'caption-color-');
            return captionColor !== null && captionColor !== void 0 ? captionColor : backgroundColor;
        }
        return subtitlesDefaults.speakerColor;
    };
    return (_jsxs(_Fragment, { children: [_jsx(SpeakerTimestamp, { position: position, widgetTrigger: widgetTrigger }), isCaptionMode === true && (_jsxs(_Fragment, { children: [_jsx(SpeakerColorButton, { widgetTrigger: widgetTrigger, position: position, disabled: disabled, editorController: editorController, initialColor: getSpeakerColor() }), _jsx(SpeakerSign, { position: position, widgetTrigger: parentTriggerNode, disabled: disabled, editorController: editorController })] }))] }));
};
export default SpeakerWidget;
