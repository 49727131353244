import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Divider, Form, Input, InputNumber, Select, Checkbox, Spin, App, } from 'antd';
import FormModal from 'components/FormModal';
import LanguageSelect from 'components/LanguageSelect';
import { createUser, addUserClaims, fetchAllTeamOwnerUsers, fetchAllUsersSimplified, } from 'api/admin/users-api';
import { fetchPasswordSettings, } from 'api/user-api';
import { addTeamMember } from 'api/admin/teams-api';
import { getUiLocales, getCurrentUiLocale } from 'libs/locales/ui';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { generatePassword } from 'libs/password';
import { filterOption } from 'libs/filterOption';
import { regexThousandsSeparator } from '../../EditUserSubpage/TeamProfile/CreditSettings';
import './style.less';
const { Option } = Select;
const NewUserForm = ({ onCreate, onCancel, modalVisible, }) => {
    const [form] = Form.useForm();
    const { session } = useSession();
    const { message } = App.useApp();
    const history = useHistory();
    const [isEmailTaken, setIsEmailTaken] = useState(false);
    const [passwordSettings, setPasswordSettings] = useState('loading');
    const [userEmails, setUserEmails] = useState([]);
    const [teamOwnerUsers, setTeamOwnerUsers] = useState('loading');
    const [addedToTeam, setAddedToTeam] = useState(false);
    const unlimitedCredit = session.login.hasClaim('dataTheme:msp');
    useEffect(() => {
        if (modalVisible) {
            void (async () => {
                const usersList = await fetchAllUsersSimplified(session.connection);
                const pwdSettings = await fetchPasswordSettings();
                setUserEmails(usersList.list.map(({ email }) => email));
                setPasswordSettings(pwdSettings);
                setTeamOwnerUsers(await fetchAllTeamOwnerUsers(session.connection));
                form.setFieldValue('password', generatePassword(pwdSettings));
            })();
        }
    }, [modalVisible]);
    const handleIsEmailRegistered = ({ target: { value } }) => {
        const trimmedEmail = value.trim();
        setIsEmailTaken(userEmails.some((email) => (email === null || email === void 0 ? void 0 : email.toLowerCase()) === trimmedEmail.toLowerCase()));
    };
    const reportOnSubmitError = (error) => {
        let loggerError;
        let messageError;
        let extraText = '';
        switch (error.errorMessage) {
            case 'Low password complexity\nPassword':
                loggerError = 'Low password complexity\nPassword';
                messageError = 'minCharsPsw';
                if (passwordSettings !== 'loading') {
                    extraText = String(passwordSettings.requiredLength);
                }
                break;
            case 'Unknown error when parsing body':
                loggerError = 'Missing number of credits.';
                messageError = 'missingCredit';
                break;
            case 'Invalid email':
                loggerError = 'Invalid email';
                messageError = 'pleaseEmail';
                break;
            case 'Duplicit email\nemail':
                loggerError = 'Duplicate email';
                messageError = 'alreadyRegistered';
                void message.error(txt('emailRegistered'));
                break;
            default:
                loggerError = 'Creating user failed';
                messageError = 'failedToCreate';
        }
        global.logger.error(loggerError, {}, error);
        void message.error(txt(messageError) + extraText);
    };
    const handleAddToTeam = (e) => {
        setAddedToTeam(e.target.checked);
        if (e.target.checked) {
            form.setFieldsValue({ credit: 0 });
        }
    };
    const handleSubmit = async (values) => {
        const { email, role, password, credit, tutorial, sendEmail, uiLocale, teamId, } = values;
        try {
            const newUser = addedToTeam
                ? await addTeamMember(session.connection, email, role, password, tutorial, sendEmail, uiLocale, teamId) : await createUser(session.connection, email, role, password, credit, tutorial, sendEmail, uiLocale);
            await addUserClaims(session.connection, newUser.id, ['paidFeatures:enabled']);
            void message.success(txt('newUserCreated'), 2);
            onCreate();
            form.resetFields();
            history.push(`/admin/users/edituser/${newUser.id}`);
        }
        catch (error) {
            reportOnSubmitError(error);
        }
    };
    return (_jsx(FormModal, { open: modalVisible, footer: [
            _jsx(Button, { type: "default", onClick: onCancel, children: txt('cancel') }, "back"),
            _jsx(Button, { type: "primary", htmlType: "submit", onClick: () => form.submit(), children: txt('create') }, "submit")
        ], onCancel: onCancel, title: txt('newUser'), children: _jsxs(Form, { initialValues: {
                role: 'Standard',
                password: '',
                credit: unlimitedCredit ? 10000000 : 0,
                tutorial: true,
                sendEmail: true,
                uiLocale: getCurrentUiLocale(),
            }, form: form, onFinish: handleSubmit, className: "new-user-form", children: [_jsx(Form.Item, { className: "new-user-form__primary-inputs", label: txt('email'), name: "email", rules: [
                        {
                            required: true,
                            message: txt('pleaseEmail'),
                            transform: (value) => value.trim(),
                            type: 'email',
                        },
                        {
                            max: 254,
                            message: txt('maxCharacters'),
                        },
                        {
                            validator: async (_, value) => {
                                if (value !== '' && isEmailTaken) {
                                    return Promise.reject(txt('alreadyRegistered'));
                                }
                                return Promise.resolve();
                            },
                        },
                    ], children: _jsx(Input, { onChange: handleIsEmailRegistered, autoFocus: true }) }), _jsx(Form.Item, { className: "new-user-form__primary-inputs", name: "role", label: txt('role'), rules: [{ required: true }], children: _jsxs(Select, { children: [_jsx(Option, { value: "Enterprise", children: "Enterprise" }, "enterprise"), _jsx(Option, { value: "Standard", children: "Standard" }, "standard"), _jsx(Option, { value: "Admin", children: "Admin" }, "admin"), _jsx(Option, { value: "Developer", children: "Developer" }, "developer")] }) }), passwordSettings === 'loading' ? _jsx(Spin, {}) : (_jsx(Form.Item, { className: "new-user-form__primary-inputs", name: "password", label: txt('pw'), rules: [
                        {
                            required: true,
                            message: txt('pleasePW'),
                        },
                        {
                            min: passwordSettings.requiredLength,
                            whitespace: true,
                            message: `${txt('minChars')}${passwordSettings.requiredLength}`,
                        },
                        {
                            max: 128,
                            message: txt('maxCharacters'),
                        },
                    ], children: _jsx(Input, {}) })), _jsx(Form.Item, { className: "new-user-form__primary-inputs", label: txt('addToTeam'), children: _jsx(Checkbox, { onChange: handleAddToTeam }) }), addedToTeam && teamOwnerUsers !== 'loading' ? (_jsx(Form.Item, { name: "teamId", label: `${txt('chooseTeam')}:`, className: "new-user-form__primary-inputs", rules: [
                        {
                            required: true,
                            message: txt('chooseTeam'),
                        },
                    ], children: _jsx(Select, { placeholder: txt('teamOwner'), showSearch: true, filterOption: filterOption, children: teamOwnerUsers.map((user) => (_jsx(Option, { value: user.teamId, label: user.email, children: user.email }, user.teamId))) }) })) : null, _jsx(Form.Item, { className: "new-user-form__primary-inputs", name: "credit", label: txt('creditMinutes'), tooltip: addedToTeam ? { title: txt('sharedCredit') } : null, rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 0,
                            max: unlimitedCredit ? 10000000 : 100000,
                            message: `${txt('enterCredit')} ${unlimitedCredit ? '10 000 000' : '100 000'}`,
                        },
                    ], children: _jsx(InputNumber, { className: "new-user-form__primary-inputs-credit", precision: 0, disabled: addedToTeam, formatter: (value) => (value === undefined ? String(0) : value.toString().replace(regexThousandsSeparator, ' ')), 
                        // NOTE: Parse back to number and remove spaces. spaces
                        parser: (value) => (value === undefined ? 0 : Number(value.replace(/\$\s?|( *)/g, ''))) }) }), _jsx(Divider, {}), _jsx(Form.Item, { className: "new-user-form__additional-input new-user-form__additional-input--inline", label: txt('addBeeyTutorial'), name: "tutorial", valuePropName: "checked", children: _jsx(Checkbox, {}) }), _jsx(Form.Item, { className: "new-user-form__additional-input new-user-form__additional-input--inline", label: txt('infoEmail'), name: "sendEmail", valuePropName: "checked", children: _jsx(Checkbox, {}) }), _jsx(Form.Item, { className: "new-user-form__additional-input", name: "uiLocale", label: txt('language'), children: _jsx(LanguageSelect, { locales: getUiLocales() }) })] }) }));
};
export default NewUserForm;
