import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Typography } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import AlertWithContactLink from 'components/AlertWithLink';
import { Link } from 'react-router-dom';
import styles from './style.module.less';
const { Text } = Typography;
const MediaOriginsSummary = ({ mediaOriginsList, availableCredit, remainingStorage, }) => {
    var _a;
    const { session: { login } } = useSession();
    if (mediaOriginsList.isEmpty()) {
        return null;
    }
    const storageAlert = mediaOriginsList.hasEnoughStorage(remainingStorage) || login.hasClaim('unlimitedStorage:enabled')
        ? null
        : (_jsxs(_Fragment, { children: [login.hasClaim('signIn:apa') ? (_jsxs(_Fragment, { children: ["Sie haben nicht gen\u00FCgend Speicherplatz. Wenden Sie sich bitte an APA-Salesdesk:", _jsx(IconButton, { onClick: () => {
                                window.location.href = 'mailto:salesdesk@apa.at';
                            }, children: "salesdesk@apa.at" })] })) : (_jsxs("p", { children: [txt('notEnoughStorageLong'), ' '] })), _jsx("strong", { className: styles.contactMessage, children: login.hasClaim('orders:create') || login.hasClaim('signIn:apa')
                        ? (_jsxs(_Fragment, { children: [txt('infoOnStorage'), ' ', _jsx(Link, { to: "/account/credit", children: txt('account').toLowerCase() }), "."] }))
                        : login.hasClaim('team:owner') ? txt('contactDistributor') : txt('contactTeamOwner') })] }));
    const creditAlert = mediaOriginsList.hasEnoughCredit(availableCredit)
        || mediaOriginsList.containsLongMedia()
        ? null
        : (_jsxs(_Fragment, { children: [login.hasClaim('signIn:apa') ? (_jsxs(_Fragment, { children: ["Sie haben nicht genug Guthaben. Wenden Sie sich bitte an APA-Salesdesk:", _jsx(IconButton, { onClick: () => {
                                window.location.href = 'mailto:salesdesk@apa.at';
                            }, children: "salesdesk@apa.at" })] })) : (_jsxs("span", { children: [txt('notEnoughCredit'), ' '] })), login.hasClaim('orders:create') || login.hasClaim('signIn:apa')
                    ? null
                    : (_jsx(Text, { strong: true, className: styles.contactMessage, children: login.hasClaim('team:owner') ? txt('contactDistributor') : txt('contactTeamOwner') }))] }));
    const getMediaOriginError = () => {
        if (mediaOriginsList.containsError('invalid-format')) {
            return {
                message: txt('mediaFormatUnsupported'),
            };
        }
        if (mediaOriginsList.containsError('not-available')) {
            return {
                message: txt('urlUnavailable'),
                linkText: txt('orContactUs'),
            };
        }
        return {
            message: txt('mediaOriginError'),
            linkText: txt('orContactUs'),
        };
    };
    const getMediaAlert = () => {
        if (mediaOriginsList.containsError()) {
            return getMediaOriginError();
        }
        if (mediaOriginsList.containsLongMedia()) {
            return {
                message: txt('extraLongRecording'),
            };
        }
        if (mediaOriginsList.containsMediumMedia()) {
            return {
                message: txt('longRecording'),
                type: 'warning',
            };
        }
        return null;
    };
    const mediaAlert = getMediaAlert();
    if (creditAlert === null && mediaAlert === null && storageAlert === null) {
        return null;
    }
    return (_jsxs("div", { className: styles.mediaOriginsSummary, children: [creditAlert !== null
                && (_jsx(Alert, { message: creditAlert, type: "error", showIcon: true })), storageAlert !== null && (_jsx(Alert, { message: storageAlert, type: "error", showIcon: true })), mediaAlert !== null && (mediaAlert.linkText !== undefined ? (_jsx(AlertWithContactLink, { message: mediaAlert.message, type: (_a = mediaAlert.type) !== null && _a !== void 0 ? _a : 'error', linkText: mediaAlert.linkText })) : (_jsx(Alert, { message: mediaAlert.message, type: mediaAlert.type, showIcon: true })))] }));
};
export default MediaOriginsSummary;
