import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Form, Select, } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { filterOption } from 'libs/filterOption';
import FormModal from 'components/FormModal';
import styles from './style.module.less';
const SectionTagsModal = ({ onCancel, onSelectTags, targetNode, }) => {
    const { session } = useSession();
    const { teamSettings: { sectionTags = [] } } = session;
    const [form] = Form.useForm();
    const options = sectionTags.map((tag) => ({ label: tag.title, value: tag.title }));
    const saveTags = () => {
        if (targetNode === null) {
            throw Error('cannot save section tags, target node is null.');
        }
        const newlySelectedTag = form.getFieldValue('sectionTag');
        onSelectTags(newlySelectedTag === undefined ? [] : [newlySelectedTag], targetNode);
    };
    return (_jsx(FormModal, { title: txt('addTags'), open: true, onCancel: onCancel, muteShortcuts: ['Tab', 'Shift+Tab'], footer: [
            _jsx(Button, { onClick: () => form.submit(), htmlType: "submit", type: "primary", children: txt('save') }, "submit"),
        ], children: _jsx(Form, { className: "section-tags-modal", form: form, onFinish: saveTags, children: _jsx(Form.Item, { name: "sectionTag", children: _jsx(Select, { className: styles.select, autoFocus: true, filterOption: filterOption, showSearch: true, options: options }) }) }) }));
};
export default SectionTagsModal;
