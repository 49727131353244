import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Alerts } from 'components/Alerts';
import Helmet from 'react-helmet';
import Screen from 'components/Screen';
import { useSession } from 'components/Authenticated';
import LeadPanel from 'components/LeadPanel';
import { useAppContext } from 'App/app-context';
import { txt } from 'libs/i18n';
import { getAppTheme } from '../DashboardScreen/components/DashboardPage';
import css from './style.module.less';
const urlParams = new URLSearchParams(window.location.search);
const SECONDS_BEFORE_REDIRECT = 10;
const PaymentScreen = () => {
    const [countdown, setCountdown] = useState({
        seconds: SECONDS_BEFORE_REDIRECT, redirectUrl: null,
    });
    const { session } = useSession();
    useAppContext();
    useEffect(() => {
        if (urlParams.get('Success') === 'true') {
            const timer = setInterval(() => {
                const count = ({ seconds }) => {
                    if (seconds > 0) {
                        return { seconds: seconds - 1, redirectUrl: null };
                    }
                    clearInterval(timer);
                    return { seconds: 0, redirectUrl: '/account' };
                };
                setCountdown(count);
            }, 1000);
            return () => clearInterval(timer);
        }
        return undefined;
    }, []);
    return (_jsx(Screen, { intro: true, children: _jsxs(LeadPanel, { redirectURL: countdown.redirectUrl, state: { tab: 'creditInfo' }, children: [_jsx(Helmet, { bodyAttributes: { 'data-theme': `${getAppTheme(session)}` }, title: txt('payment') }), urlParams.get('Success') === 'true' ? Alerts.success(txt('paymentSuccess')) : Alerts.error(txt('paymentFailed'), urlParams.get('ResultText')), _jsxs("div", { className: css.paymentScreenControls, children: [urlParams.get('Success') === 'true'
                            ? (_jsx("div", { className: css.redirection, children: countdown.seconds > 0
                                    ? `${txt('automaticRedirect')} ${countdown.seconds} s.`
                                    : txt('redirection') }))
                            : null, _jsx(Button, { type: "primary", size: "large", onClick: () => setCountdown(Object.assign(Object.assign({}, countdown), { redirectUrl: '/account' })), children: txt('continue') })] })] }) }));
};
export default PaymentScreen;
