import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CloseCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { Badge, Flex, Popconfirm, Spin, Tag, Tooltip, } from 'antd';
import clsx from 'clsx';
import { txt } from 'libs/i18n';
import { deriveTagColor, deriveTagStyle } from 'libs/tag-style';
import TagFormItems from 'components/AddEditModal/TagFormItems';
import { useState } from 'react';
import AddEditModal from 'components/AddEditModal';
import styles from './style.module.less';
const { CheckableTag } = Tag;
const BeeyTag = ({ tag, isCheckable, isForeign, openAddEditModal, onModalOpen, modalAction, checked, partiallyChecked, onTagCheckChange, onTagDelete, onTagEdit, loadingTags, disabled, }) => {
    var _a, _b, _c, _d;
    const [popConfirmOpen, setPopconfirmOpen] = useState(false);
    const isTagLoading = (_a = loadingTags === null || loadingTags === void 0 ? void 0 : loadingTags.includes(tag.title)) !== null && _a !== void 0 ? _a : false;
    const areTagsLoading = ((_b = loadingTags === null || loadingTags === void 0 ? void 0 : loadingTags.length) !== null && _b !== void 0 ? _b : 0) > 0;
    const tagAbbreviation = (_c = tag.abbreviation) !== null && _c !== void 0 ? _c : tag.title.slice(0, 2);
    const createTagStyle = () => {
        const isFullColored = !isCheckable || checked;
        return deriveTagStyle(tag.title, tag.color, partiallyChecked, isForeign, isFullColored);
    };
    const handleTagCheckChange = (checkedBool) => {
        if (!areTagsLoading && disabled !== true) {
            onTagCheckChange === null || onTagCheckChange === void 0 ? void 0 : onTagCheckChange(tag.title, checkedBool);
        }
    };
    const handleEditTag = (editedTag) => {
        void (onTagEdit === null || onTagEdit === void 0 ? void 0 : onTagEdit(editedTag));
        onModalOpen === null || onModalOpen === void 0 ? void 0 : onModalOpen(false);
    };
    const confirmDelete = (_jsx("div", { children: _jsxs("p", { children: [_jsxs("strong", { children: [txt('deleteTag'), ' ', "?"] }), _jsx("br", {}), txt('deleteTagPermanently'), _jsx("br", {})] }) }));
    const saveIcon = (_jsx(Tooltip, { title: txt('saveTag'), children: _jsx(PushpinOutlined, { onClick: () => onModalOpen === null || onModalOpen === void 0 ? void 0 : onModalOpen(true), className: styles.saveIcon }) }));
    const deleteIcon = (_jsx(Popconfirm, { title: confirmDelete, overlayClassName: "tag-popover", okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => onTagDelete === null || onTagDelete === void 0 ? void 0 : onTagDelete(tag.title), cancelText: txt('cancel'), onOpenChange: () => setPopconfirmOpen(!popConfirmOpen), children: _jsx(Tooltip, { title: txt('deleteTag'), children: _jsx(CloseCircleOutlined, { className: styles.deleteIcon, onClick: () => setPopconfirmOpen(!popConfirmOpen) }) }) }));
    const badgeIcon = (tag.showFullVersion === true ? (_jsxs(Flex, { children: [saveIcon, deleteIcon] })) : '');
    const renderTagContent = () => {
        const tooltipTitle = tag.showFullVersion === true ? false : tag.title;
        const tagClassName = clsx(styles.beeyTag, tag.showFullVersion !== true ? styles.shortTag : '');
        const tagContent = tag.showFullVersion === true ? tag.title : tagAbbreviation;
        const tagElement = (_jsx(Tag, { className: tagClassName, style: createTagStyle(), children: tagContent }));
        if (isCheckable) {
            return (_jsx(Tooltip, { title: disabled === true ? txt('readOnlyProject') : false, children: _jsxs(CheckableTag, { checked: checked !== null && checked !== void 0 ? checked : false, style: createTagStyle(), onChange: handleTagCheckChange, className: clsx((areTagsLoading && !isTagLoading) || disabled === true ? styles.beeyTagDisabled : '', styles.beeyTag), children: [isTagLoading && _jsx(Spin, { size: "small" }), tag.title] }) }));
        }
        if (isForeign === true) {
            return (_jsx(Tooltip, { title: tooltipTitle, children: _jsx(Badge, { count: badgeIcon, offset: [-15, 0], children: tagElement }) }));
        }
        return _jsx(Tooltip, { title: tooltipTitle, children: tagElement });
    };
    return (_jsxs(_Fragment, { children: [renderTagContent(), modalAction !== undefined && (_jsx(AddEditModal, { action: modalAction, visible: openAddEditModal === true, title: (_jsx(_Fragment, { children: txt('saveTag') })), initialValues: {
                    title: tag.title,
                    abbreviation: tagAbbreviation,
                    color: (_d = tag.color) !== null && _d !== void 0 ? _d : deriveTagColor(tag.title),
                    isPinned: tag.isPinned,
                }, labelColSpan: 10, wrapperColSpan: 14, onCancel: () => onModalOpen === null || onModalOpen === void 0 ? void 0 : onModalOpen(false), onEdit: handleEditTag, children: _jsx(TagFormItems, { editedTag: tag }) }))] }));
};
export default BeeyTag;
