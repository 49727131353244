import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json } from '@newtontechnologies/beey-api-js-client/receivers';
const PAGE_LIMIT = (2 ** 31) - 1;
export const saveAnnouncement = async (connection, localizations, teamIds, from, to, importance) => {
    const body = {
        From: from,
        To: to,
        TeamIds: teamIds,
        Importance: importance,
        Localizations: localizations,
    };
    return connection.authFetch()
        .url(apiV2.system.announcements.url())
        .postJson(body)
        .send();
};
export const fetchAnnouncements = async (connection, from, to, params = {}) => {
    var _a, _b;
    const skip = (_a = params.skip) !== null && _a !== void 0 ? _a : 0;
    const count = (_b = params.count) !== null && _b !== void 0 ? _b : PAGE_LIMIT;
    return connection.authFetch()
        .url(apiV2.system.announcements.url({
        from, to, skip, count,
    }))
        .receive(json())
        .fetch();
};
export const fetchAnnouncementsAdmin = async (connection, from, to, params = {}) => {
    var _a, _b;
    const skip = (_a = params.skip) !== null && _a !== void 0 ? _a : 0;
    const count = (_b = params.count) !== null && _b !== void 0 ? _b : 2147483647;
    return connection.authFetch()
        .url(apiV2.system.announcements.editable.url({
        from, to, skip, count,
    }))
        .receive(json())
        .fetch();
};
export const deleteAnnouncement = async (connection, announcementId) => connection.authFetch()
    .url(apiV2.system.announcements.id(announcementId).url())
    .delete()
    .send();
