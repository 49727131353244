import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Slider } from 'antd';
import { HideVidIcon, ShowVidIcon, SoundWaveIcon, SoundWaveIconHide, SpeedIcon, VolumeMiddleIcon, VolumeOffIcon, VolumeTopIcon, } from 'components/CustomIcons';
import ResponsiveTooltip from 'components/ResponsiveTooltip';
import { useEffect, useState } from 'react';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import { MAX_SPEED, MIN_SPEED, PLAYBACK_RATE_STEP, PlaybackEvents, VOLUME_STEP, } from '../../MediaPlayer/playback';
import { usePlaybackContext } from '../../WithMediaPlayback';
import styles from './style.module.less';
const PlayerSettings = ({ isCaptionMode, isWaveVisible, onWaveVisibilityChange, }) => {
    const { playback, playerState, setShowVideo } = usePlaybackContext();
    const [playerSpeed, setPlayerSpeed] = useState(Number(clientEnv.getPlayerSpeed()));
    const [volume, setVolume] = useState(Number(clientEnv.getVolume()));
    useEffect(() => {
        const handleSpeedUpdate = (speed) => {
            setPlayerSpeed(Number(speed.toFixed(1)));
        };
        const handleVolumeUpdate = (vol) => {
            setVolume(Number(vol) * 100);
        };
        playback.addEventListener(PlaybackEvents.SpeedUpdate, handleSpeedUpdate);
        playback.addEventListener(PlaybackEvents.VolumeUpdate, handleVolumeUpdate);
        return () => {
            playback.removeEventListener(PlaybackEvents.SpeedUpdate, handleSpeedUpdate);
            playback.removeEventListener(PlaybackEvents.VolumeUpdate, handleVolumeUpdate);
        };
    }, []);
    const volumeIcon = () => {
        if (volume === 0) {
            return _jsx(VolumeOffIcon, { className: styles.volumeIcon });
        }
        if (volume > 0 && volume <= 50) {
            return _jsx(VolumeMiddleIcon, { className: styles.volumeIcon });
        }
        return _jsx(VolumeTopIcon, { className: styles.volumeIcon });
    };
    const playerIsReady = playerState.status === 'ready';
    const speedFormatter = playerIsReady ? (sliderValue) => `${sliderValue}x` : null;
    const volumeFormatter = playerIsReady ? (sliderValue) => `${sliderValue} %` : null;
    const handleSpeedChange = (value) => {
        playback.setSpeedTo(value);
        clientEnv.setPlayerSpeed(value);
    };
    const handleVolumeChange = (value) => {
        playback.setVolumeTo(value / 100);
        clientEnv.setVolume(value);
    };
    const toggleVideoVisible = () => {
        if (playerState.video === 'no-video') {
            return;
        }
        setShowVideo(!playerState.video.isVisible, playerState.video.isMaximized);
    };
    const showDivider = playerState.video !== 'no-video' || isCaptionMode;
    return (_jsxs("div", { className: styles.sliders, children: [_jsxs("div", { className: styles.speed, children: [_jsx(SpeedIcon, { className: styles.speedIcon }), _jsx(Slider, { className: styles.slider, min: MIN_SPEED, max: MAX_SPEED, step: PLAYBACK_RATE_STEP, marks: {
                            0.2: '0.2', 1: '1', 1.5: '1.5', 2.5: '2.5',
                        }, value: playerSpeed, onChange: handleSpeedChange, tooltip: { formatter: speedFormatter }, disabled: !playerIsReady })] }), _jsxs("div", { className: styles.volume, children: [volumeIcon(), _jsx(Slider, { className: styles.slider, min: 0, max: 100, marks: {
                            0: '0', 100: '1',
                        }, step: VOLUME_STEP, value: volume, onChange: handleVolumeChange, tooltip: { formatter: volumeFormatter }, disabled: !playerIsReady })] }), showDivider && _jsx(Divider, { className: styles.divider }), _jsxs("div", { className: styles.view, children: [_jsx("span", { children: `${txt('view')}:` }), _jsxs("div", { className: styles.viewButtons, children: [isCaptionMode && (_jsx(ResponsiveTooltip, { title: isWaveVisible ? txt('hideWaveCanvas') : txt('displayWaveCanvas'), children: _jsx("button", { type: "button", onClick: () => onWaveVisibilityChange(!isWaveVisible), className: styles.viewButton, children: isWaveVisible
                                        ? _jsx(SoundWaveIconHide, { className: styles.waveIcon })
                                        : _jsx(SoundWaveIcon, { className: styles.waveIcon }) }) })), playerState.video !== 'no-video' ? (_jsx(ResponsiveTooltip, { title: playerState.video.isVisible ? txt('hideVid') : txt('showVid'), children: _jsx("button", { type: "button", onClick: toggleVideoVisible, className: styles.viewButton, children: playerState.video.isVisible
                                        ? _jsx(HideVidIcon, { className: styles.videoIcon })
                                        : _jsx(ShowVidIcon, { className: styles.videoIcon }) }) })) : null] })] })] }));
};
export default PlayerSettings;
