const hexColors = [
    '#E7E7E7',
    '#C2C2C2',
    '#FFC8AF',
    '#F96D2F',
    '#BDD6FB',
    '#4986E7',
    '#FFDEB5',
    '#FFAD46',
    '#98D7E4',
    '#2DA2BB',
    '#FBE983',
    '#EBDBDE',
    '#E3D7FF',
    '#B99AFF',
    '#FDEDC1',
    '#CCA6AC',
    '#FBD3E0',
    '#F691B2',
    '#B3EFD3',
    '#42D692',
    '#F2B2A8',
    '#FB4C2F',
    '#A2DCC1',
    '#16A765',
    '#FF1744',
    '#6A1B9A',
    '#EA80FC',
    '#84FFFF',
    '#B7E901',
    '#AB214A',
];
// NOTE: Simple hash for strings
// SEE: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
const hashCode = (s) => {
    let h = 0;
    for (let i = 0; i < s.length; i += 1) {
        h = Math.imul(31, h) + s.charCodeAt(i) || 0;
    }
    return Math.abs(h);
};
export const getFontColor = (hex) => {
    const newHex = hex.replace('#', '');
    // NOTE The hex code can have 8 characters if alpha level (transparency) is added
    if (newHex.length !== 6 && newHex.length !== 8) {
        return '';
    }
    const red = parseInt(newHex.substring(0, 2), 16);
    const green = parseInt(newHex.substring(2, 4), 16);
    const blue = parseInt(newHex.substring(4, 6), 16);
    const luminance = red * 0.2126 + green * 0.7152 + blue * 0.0722;
    return luminance < 140 ? 'white' : 'black';
};
export const deriveTagColor = (tagName) => {
    const hash = hashCode(tagName.toLowerCase());
    return hexColors[hash % hexColors.length];
};
export const deriveTagStyle = (tagName, customColor = null, isPartiallyChecked = false, isForeign = false, isFullColored = true) => {
    const tagColor = customColor !== null && customColor !== void 0 ? customColor : deriveTagColor(tagName);
    const isBordered = isPartiallyChecked || isForeign;
    if (!isBordered) {
        return isFullColored
            ? {
                border: 'none',
                backgroundColor: tagColor,
                color: getFontColor(tagColor),
            }
            : {
                border: '0.5px dashed var(--ant-color-text-disabled)',
                backgroundColor: 'transparent',
                color: 'black',
            };
    }
    if (isForeign) {
        return {
            border: `${tagColor} 2px solid`,
        };
    }
    if (isPartiallyChecked) {
        return {
            border: `${tagColor} 2px dashed`,
        };
    }
    return {
        border: 'none',
        backgroundColor: tagColor,
        color: getFontColor(tagColor),
    };
};
