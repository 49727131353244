import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'antd';
import { txt } from 'libs/i18n';
const PurchaseButton = ({ isSubscribed, lookupKey, quantity, isCurrentPlan, onCreateOrder, onGetStripeCustomerPortal, isCanceled, }) => {
    const isSingleUser = lookupKey === 'beey_start';
    const isEnterprise = lookupKey === 'enterprise';
    if (isSubscribed) {
        if (isSingleUser && isCanceled) {
            return _jsx(Button, { type: "primary", onClick: () => onCreateOrder(lookupKey, quantity), children: txt('topUpCredit') });
        }
        if (isSingleUser) {
            return _jsx(Button, { type: "primary", onClick: () => onGetStripeCustomerPortal('subscription_cancel'), children: txt('cancelPaidPlan') });
        }
        if (isCurrentPlan) {
            return _jsx(Button, { type: "primary", disabled: true, children: txt('currentPlan') });
        }
        if (!isEnterprise) {
            return _jsx(Button, { type: "primary", onClick: () => onGetStripeCustomerPortal('subscription_update_confirm'), children: txt('getPlan') });
        }
    }
    else {
        if (isSingleUser) {
            return _jsx(Button, { type: "primary", disabled: true, children: txt('currentPlan') });
        }
        if (!isEnterprise) {
            return _jsx(Button, { type: "primary", onClick: () => onCreateOrder(lookupKey, quantity), children: txt('getPlan') });
        }
    }
    return _jsx(Button, { type: "primary", onClick: () => window.open('https://www.beey.io/cs/kontakty/', '_blank'), children: txt('contactSales') });
};
export default PurchaseButton;
