import { jsx as _jsx } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import css from './style.module.less';
const LiveUpdateStatus = ({ socketStatus }) => {
    const { isDown, isGivenup, reviveAttemptCount } = socketStatus;
    if (!isDown) {
        return null;
    }
    return (_jsx("div", { className: css.noConnection, children: isGivenup
            ? txt('givenup')
            : `${txt('reconnecting')}... ${reviveAttemptCount}` }));
};
export default LiveUpdateStatus;
