import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Input } from 'antd';
import FormModal from 'components/FormModal';
import { WarningFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { txt } from 'libs/i18n';
import css from './style.module.less';
const WarningMessage = ({ isTeamOwner }) => (_jsxs(_Fragment, { children: [_jsx("span", { className: css.bold, children: txt('warning') }), isTeamOwner ? (_jsxs(_Fragment, { children: [txt('processIrreversibleTeamPartOne'), ' ', _jsx("span", { className: css.bold, children: txt('processIrreversibleTeamPartTwo') })] })) : (txt('processIrreversible'))] }));
const DeleteModal = ({ visible, onCancel, email, isTeamOwner, onDelete, redirectTo, }) => {
    const [inputMatchesEmail, setInputMatchesEmail] = useState(false);
    const history = useHistory();
    const verifyEmailMatch = ({ target: { value } }) => {
        setInputMatchesEmail(email === value.trim());
    };
    const deleteEntity = () => {
        onDelete();
        history.push(redirectTo);
    };
    return (_jsxs(FormModal, { className: css.deleteModal, title: txt(`${isTeamOwner ? 'deleteTeam' : 'deleteUserPermanently'}`), open: visible, onCancel: onCancel, onOk: deleteEntity, footer: [
            _jsx(Button, { onClick: onCancel, children: txt('close') }, "back"),
            _jsx(Button, { type: "primary", danger: true, disabled: !inputMatchesEmail, onClick: deleteEntity, children: txt('delete') }, "submit"),
        ], children: [_jsxs("p", { children: [_jsx(WarningFilled, {}), "\u00A0 \u00A0", _jsx(WarningMessage, { isTeamOwner: isTeamOwner })] }), _jsxs("p", { children: [txt('pleaseTypeEmail'), _jsx("span", { className: css.bold, children: email }), txt('verifyAction')] }), _jsx(Input, { onChange: verifyEmailMatch, autoFocus: true })] }));
};
export default DeleteModal;
