import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Spin, Tabs, Collapse } from 'antd';
import { fetchAllTranscriptionOptions } from 'api/admin/information-api';
import { listApps } from '@newtontechnologies/beey-api-js-client/apps-api';
import { editorClaimList, subtitlesClaimList } from 'api/user-claims';
import { useSession } from 'components/Authenticated';
import { txt, fragileTxt, localeTxt } from 'libs/i18n';
import Claim from './Claim';
import LanguageProfiles from './LanguageProfiles';
import css from './style.module.less';
const UserClaims = ({ userid, claimSet }) => {
    const { session } = useSession();
    const [appList, setAppList] = useState(null);
    const [transcriptionOptions, setTranscriptionOptions,] = useState('loading');
    const getAppsList = async () => {
        try {
            const apps = await listApps(session.connection);
            const receivedAppList = apps.list.map((app) => ({
                title: `App ${app.name}:`,
                ApiName: `apps:${app.name}`,
            }));
            setAppList(receivedAppList);
        }
        catch (error) {
            global.logger.error('Failed to get apps list', {}, error);
        }
    };
    const getTranscriptionOptions = async () => {
        try {
            const options = await fetchAllTranscriptionOptions(session.connection);
            setTranscriptionOptions(options.sort((opt1, opt2) => (localeTxt(opt1.locale).localeCompare(localeTxt(opt2.locale)))));
        }
        catch (error) {
            global.logger.error('Failed to get transcription profiles list', {}, error);
        }
    };
    useEffect(() => {
        void getAppsList();
        void getTranscriptionOptions();
    }, []);
    if (claimSet === null || appList === null || transcriptionOptions === 'loading') {
        return _jsx(Spin, { className: css.claimsSpin });
    }
    const getLocalizedTitle = (key) => {
        var _a;
        // some titles are in localisation and some are not, such as "App link"
        const text = (_a = fragileTxt(key)) !== null && _a !== void 0 ? _a : key;
        return text.endsWith(':') ? text : `${text}:`;
    };
    const tabPanes = [
        {
            key: '1',
            label: txt('edit'),
            children: (_jsxs(_Fragment, { children: [editorClaimList.map((claim) => (_jsx(Claim, { userid: userid, claimTitle: getLocalizedTitle(claim.title), claimAPIName: claim.ApiName, claimSet: claimSet }, claim.title))), _jsx(Collapse, { bordered: false, items: [
                            {
                                key: '1',
                                label: txt('subtitles'),
                                children: (_jsx(_Fragment, { children: subtitlesClaimList.map((claim) => (_jsx(Claim, { userid: userid, claimTitle: getLocalizedTitle(claim.title), claimAPIName: claim.ApiName, claimSet: claimSet }, claim.title))) })),
                            }
                        ] })] })),
        },
        {
            key: '2',
            label: txt('apps'),
            children: (_jsxs(_Fragment, { children: [_jsx(Claim, { userid: userid, claimTitle: txt('allApps'), claimAPIName: "apps:all", claimSet: claimSet }, "allApps"), session.login.hasClaim('apps:all')
                        ? (_jsx(Collapse, { bordered: false, items: [{
                                    key: '1',
                                    label: txt('apps'),
                                    children: (_jsx(_Fragment, { children: appList.map((claim) => (_jsx(Claim, { userid: userid, claimTitle: claim.title, claimAPIName: claim.ApiName, claimSet: claimSet }, claim.title))) })),
                                }] }))
                        : (_jsx("p", { children: txt('appsAllMissing') }))] })),
        },
        {
            key: '3',
            label: txt('transcribers'),
            children: (_jsxs(_Fragment, { children: [_jsx(Claim, { userid: userid, claimTitle: `${txt('allTranscribers')}:`, claimAPIName: "transcriptionProfiles:all", claimSet: claimSet }, "transcriptionProfiles:all"), transcriptionOptions.map((option) => (_jsx(LanguageProfiles, { locale: option.locale, claimSet: claimSet, profiles: option.profiles, userid: userid }, option.locale.code)))] })),
        },
    ];
    return (_jsx(Tabs, { defaultActiveKey: "1", items: tabPanes }));
};
export default UserClaims;
