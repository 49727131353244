import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Form, Switch, Tooltip, Button, ColorPicker, Checkbox, } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { txt } from 'libs/i18n';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DraggableModal from 'components/DraggableModal';
import { getDialogPosition } from 'libs/dialog-position';
import { SpeakerColorSchema } from 'api/settings/user-settings';
import styles from './style.module.less';
const getSpeakerColoringFormat = (speakers, defaultColor) => {
    return speakers.reduce((acc, speaker) => {
        var _a;
        const captionColor = (_a = speaker.captionColor) !== null && _a !== void 0 ? _a : defaultColor;
        return Object.assign(Object.assign({}, acc), { [speaker.id]: {
                color: captionColor,
                isDefault: speaker.isDefaultColor,
            } });
    }, {});
};
const SpeakerColorDialog = ({ onClose, editorController, defaultSpeakerColor, }) => {
    const [form] = Form.useForm();
    const scrollableRef = useRef(null);
    const { getSpeakerColorConflict, getSpeakerFrequencyInDocument } = editorController.speakers;
    useEffect(() => {
        form.setFieldValue('defaultColor', defaultSpeakerColor);
    }, [defaultSpeakerColor]);
    const sortSpeakersByFrequency = (speakers) => {
        const frequencies = speakers.map((speaker) => editorController.speakers.getSpeakerFrequencyInDocument(speaker));
        return speakers.slice().sort((a, b) => {
            const frequencyDiff = frequencies[speakers.indexOf(b)] - frequencies[speakers.indexOf(a)];
            if (frequencyDiff === 0) {
                return a.composeLabel().localeCompare(b.composeLabel());
            }
            return frequencyDiff;
        });
    };
    const [documentSpeakers, setDocumentSpeakers] = useState(() => sortSpeakersByFrequency(editorController.speakers.getUniqueDocumentSpeakers()));
    useEffect(() => {
        var _a;
        (_a = scrollableRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
        const handleTextChanged = () => {
            const changedSpeakers = sortSpeakersByFrequency(editorController.speakers.getUniqueDocumentSpeakers());
            setDocumentSpeakers(changedSpeakers);
            form.setFieldValue('speakers', getSpeakerColoringFormat(changedSpeakers, defaultSpeakerColor));
        };
        editorController.addEventListener('text-changed', handleTextChanged);
        return () => {
            editorController.removeEventListener('text-changed', handleTextChanged);
        };
    }, []);
    const formInitialValues = {
        defaultColor: defaultSpeakerColor,
        showConflicts: false,
        speakers: getSpeakerColoringFormat(documentSpeakers, defaultSpeakerColor),
    };
    const getSpeaker = (speakerId) => {
        return documentSpeakers.find((speaker) => speaker.id === speakerId);
    };
    const changeSpeakerColor = (speakerId, color, isDefaultColor) => {
        const changedSpeaker = getSpeaker(speakerId);
        if (changedSpeaker === undefined) {
            global.logger.error('failed to change speaker color - speaker not found');
            return;
        }
        editorController.handleSpeakerColorChange(changedSpeaker, color, isDefaultColor);
    };
    const handleDefaultColorChange = (newColor) => {
        if (newColor !== defaultSpeakerColor) {
            editorController.captions.updateCaptionParameters({ defaultColor: newColor });
        }
    };
    const uncheckDefaultOnColorChange = (speakerId) => {
        const speakers = form.getFieldValue('speakers');
        form.setFieldValue('speakers', Object.assign(Object.assign({}, speakers), { [speakerId]: Object.assign(Object.assign({}, speakers[speakerId]), { isDefault: false }) }));
    };
    const handleUpdateSpeakerColor = (visible, speakerId) => {
        if (!visible) {
            const speaker = form.getFieldValue('speakers')[speakerId];
            const newColor = (typeof speaker.color === 'string' ? speaker.color : speaker.color.toHexString());
            changeSpeakerColor(speakerId, newColor, speaker.isDefault);
        }
    };
    const handleCheckedDefaultColor = (value, speakerId) => {
        const { target: { checked } } = value;
        const speakers = form.getFieldValue('speakers');
        const defaultColor = form.getFieldValue('defaultColor');
        form.setFieldValue('speakers', Object.assign(Object.assign({}, speakers), { [speakerId]: {
                color: checked ? defaultColor : speakers[speakerId].color,
                isDefault: checked,
            } }));
    };
    const autoColorSpeakers = () => {
        // NOTE: In auto color mode, most frequent speaker has default color.
        editorController.handleSpeakerColorChange(documentSpeakers[0], defaultSpeakerColor, true);
        const colors = SpeakerColorSchema.values.filter((color) => color !== defaultSpeakerColor);
        const assignedColors = editorController.speakers.assignSpeakersColors(colors);
        assignedColors.forEach((coloredSpeaker) => {
            changeSpeakerColor(coloredSpeaker.id, coloredSpeaker.color, false);
        });
    };
    return (_jsx(DraggableModal, { bounds: "body", onClose: onClose, title: txt('speakerColor'), defaultPosition: getDialogPosition({ width: 500, height: 450 }), children: _jsxs(Form, { labelCol: { span: 13 }, form: form, initialValues: formInitialValues, labelAlign: "left", labelWrap: true, className: styles.speakerForm, colon: false, children: [_jsx(Form.Item, { name: "defaultColor", label: `${txt('defaultColor')}:`, children: _jsx(ColorPicker, { className: styles.colorPicker, size: "small", onChangeComplete: (color) => handleDefaultColorChange(color.toHexString()), arrow: false, presets: [{ label: txt('primaryColors'), colors: [...SpeakerColorSchema.values] }], showText: true }) }), _jsx(Form.Item, { className: styles.controlInput, name: "autoColor", label: `${txt('autoColor')}:`, children: _jsx(Button, { size: "small", onClick: autoColorSpeakers, children: txt('assignColors') }) }), _jsx(Form.Item, { name: "showConflicts", className: styles.controlInput, valuePropName: "checked", label: `${txt('showConflicts')}:`, children: _jsx(Switch, { size: "small" }) }), _jsx("div", { className: styles.hint, children: txt('colorSpeakerHint') }), _jsx("div", { className: styles.speakerList, ref: scrollableRef, children: documentSpeakers.map((speaker) => (_jsxs("div", { className: styles.speakerFormItem, children: [_jsx(Form.Item, { name: ['speakers', speaker.id, 'color'], children: _jsx(ColorPicker, { size: "small", arrow: false, onChangeComplete: () => uncheckDefaultOnColorChange(speaker.id), onOpenChange: (visible) => handleUpdateSpeakerColor(visible, speaker.id), presets: [
                                        { label: txt('primaryColors'), colors: [...SpeakerColorSchema.values] },
                                    ], panelRender: 
                                    // eslint-disable-next-line react/no-unstable-nested-components
                                    (_, { components: { Picker, Presets } }) => (_jsxs(_Fragment, { children: [_jsx(Picker, {}), _jsxs("div", { className: styles.defaultColorWrapper, children: [_jsx(Form.Item, { name: ['speakers', speaker.id, 'isDefault'], valuePropName: "checked", noStyle: true, children: _jsx(Checkbox, { defaultChecked: speaker.isDefaultColor, onChange: (checked) => handleCheckedDefaultColor(checked, speaker.id) }) }), _jsx("span", { className: styles.defaultColorText, children: txt('chooseDefaultColor') }), _jsx("div", { className: styles.colorBox, style: { backgroundColor: defaultSpeakerColor } })] }), _jsx(Presets, {})] })) }) }), _jsx("span", { className: styles.speaker, children: speaker.composeLabel() }), _jsx(Tooltip, { title: txt('speakerFreq'), children: _jsx("span", { className: styles.speaker, children: `(${getSpeakerFrequencyInDocument(speaker)})` }) }), _jsx(Form.Item, { dependencies: ['showConflicts'], children: ({ getFieldValue }) => {
                                    const displayConflicts = getFieldValue('showConflicts');
                                    const conflictName = getSpeakerColorConflict(speaker.id);
                                    return (displayConflicts === true && conflictName !== null ? (_jsx(Tooltip, { title: txt('colorConflictError'), children: _jsxs("div", { className: styles.conflict, children: [_jsx(ExclamationCircleOutlined, {}), _jsx("span", { children: conflictName })] }) })) : null);
                                } })] }, speaker.id))) })] }) }));
};
export default SpeakerColorDialog;
