import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState, useRef } from 'react';
import { useSession } from 'components/Authenticated';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import deepEqual from 'fast-deep-equal';
import Draggable from 'react-draggable';
import { subtitlesDefaults } from 'api/settings/user-settings';
import { CAPTION_EVENTS } from '../DocumentEditor/captions';
import { PlaybackEvents } from './playback';
import { usePlaybackContext } from '../WithMediaPlayback';
// NOTE: reference image height is a value that was found heuristically.
// It appears for example that font size 25 is 25 pixels if video height
// is 312. If the video height is different, it is scaled accordingly.
const REFERENCE_IMAGE_HEIGHT = 312;
const VideoCaptions = ({ videoWidth, handleNewCaption, language, readOnly, }) => {
    var _a, _b, _c;
    const [subtitleFontSize, setSubtitleFontSize] = useState(0);
    const [caption, setCaption] = useState(null);
    const [captionPosition, setCaptionPosition] = useState(null);
    const [grabbing, setGrabbing] = useState(false);
    const captionRef = useRef(null);
    const captionLinesElementRef = useRef(null);
    const { playback, captions } = usePlaybackContext();
    const { session: { login: { user } } } = useSession();
    const updateCaption = (newCaption) => {
        if (deepEqual(captionRef.current, newCaption)) {
            // Optimization. We need to check against captionRef,
            // because state does not work in callbacks
            return;
        }
        captionRef.current = newCaption;
        setCaption(newCaption);
    };
    const convertAlignToHorizontalPosition = (align) => {
        if (align === 'left')
            return 0;
        if (align === 'center')
            return 0.5;
        return 1;
    };
    const getRects = () => {
        var _a, _b, _c;
        const captionsRect = (_a = captionLinesElementRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const parentRect = (_c = (_b = captionLinesElementRef.current) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect();
        return [captionsRect, parentRect];
    };
    const { defaultCaptionPosition } = user.settings.subtitles;
    const defaultHorizontalCaptionPosition = defaultCaptionPosition.align;
    const defaultVerticalCaptionPosition = defaultCaptionPosition.line;
    const computeCaptionPosition = () => {
        const [captionsRect, parentRect] = getRects();
        if (captionsRect === undefined || parentRect === undefined || caption === null) {
            return null;
        }
        const { format } = caption;
        const formatAlign = format.align === null
            ? defaultHorizontalCaptionPosition : format.align;
        const formatLine = format.line === null
            ? defaultVerticalCaptionPosition : format.line;
        let y = parentRect.height - captionsRect.height - 0.05 * parentRect.height; // default position
        if (formatLine !== null) {
            y = (formatLine / 100) * (parentRect.height - captionsRect.height);
        }
        let x = 0.5 * (parentRect.width - captionsRect.width);
        if (formatAlign !== null) {
            x = convertAlignToHorizontalPosition(formatAlign) * (parentRect.width - captionsRect.width);
        }
        return { x, y };
    };
    const updateFontSize = () => {
        var _a;
        if (captions.parameters === null || playback.resolution === null) {
            return;
        }
        const [, containerRect] = getRects();
        const containerHeight = (_a = containerRect === null || containerRect === void 0 ? void 0 : containerRect.height) !== null && _a !== void 0 ? _a : 0;
        const newFontSize = (captions.parameters.defaultFontSize / REFERENCE_IMAGE_HEIGHT)
            * containerHeight;
        if (newFontSize !== subtitleFontSize) {
            // caption position will be updated in separate useEffect
            setSubtitleFontSize(newFontSize);
        }
        else {
            setCaptionPosition(computeCaptionPosition());
        }
    };
    const handleTimeUpdate = (time) => {
        updateCaption(captions.getCaptionAtTime(time));
    };
    const handleCaptionUpdate = (event) => {
        const { from, to } = event;
        updateFontSize();
        if (playback.time >= from && playback.time <= to) {
            updateCaption(captions.getCaptionAtTime(playback.time));
        }
    };
    useEffect(() => {
        playback.addEventListener(PlaybackEvents.TimeUpdate, handleTimeUpdate);
        captions.addEventListener(CAPTION_EVENTS.CHANGED, handleCaptionUpdate);
        return () => {
            playback.removeEventListener(PlaybackEvents.TimeUpdate, handleTimeUpdate);
            captions.removeEventListener(CAPTION_EVENTS.CHANGED, handleCaptionUpdate);
        };
    }, []);
    useEffect(() => {
        // at first caption is rendered invisible in default position.
        // After it's size is measured, it can be positioned propperly.
        setCaptionPosition(computeCaptionPosition());
        handleNewCaption(caption);
    }, [caption, user.settings.subtitles.defaultCaptionPosition]);
    useEffect(() => {
        updateFontSize();
    }, [videoWidth]);
    useEffect(() => {
        setCaptionPosition(computeCaptionPosition());
    }, [subtitleFontSize]);
    const handleStartGrab = () => {
        setGrabbing(true);
    };
    const convertHorizontalPositionToAlign = (position) => {
        if (position < 0.2) {
            return 'left';
        }
        if (position < 0.8) {
            return 'center';
        }
        return 'right';
    };
    const handleStopDrag = () => {
        setGrabbing(false);
        const [captionsRect, parentRect] = getRects();
        if (caption === null
            || caption.lines.length === 0
            || captionLinesElementRef.current === null
            || captionsRect === undefined
            || parentRect === undefined) {
            return;
        }
        const relativeVerticalPosition = (captionsRect.top - parentRect.top)
            / (parentRect.height - captionsRect.height);
        const relativeHorizontalPosition = (captionsRect.left - parentRect.left)
            / (parentRect.width - captionsRect.width);
        const align = convertHorizontalPositionToAlign(relativeHorizontalPosition);
        const format = {
            align,
            line: Math.round(relativeVerticalPosition * 100),
        };
        captions.changeCaptionFormat(caption, format);
    };
    const getGridValues = () => {
        const [captionsRect, parentRect] = getRects();
        if (captionsRect !== undefined && parentRect !== undefined) {
            const draggableStepXaxis = (parentRect.width - captionsRect.width) / 2;
            const draggableStepYaxis = (parentRect.height - captionsRect.height) / 2;
            return [draggableStepXaxis, draggableStepYaxis];
        }
        return [1, 1];
    };
    const captionBackgroundOpacity = 1 - ((_b = (_a = captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultBackgroundTransparency) !== null && _b !== void 0 ? _b : subtitlesDefaults.backgroundTransparency);
    const isRightLoLeft = (_c = language === null || language === void 0 ? void 0 : language.isRightToLeft()) !== null && _c !== void 0 ? _c : false;
    return (_jsx("div", { className: "captions", children: _jsx(Draggable, { disabled: readOnly, bounds: "parent", position: captionPosition !== null && captionPosition !== void 0 ? captionPosition : { x: 0, y: 0 }, onStart: handleStartGrab, onStop: handleStopDrag, grid: getGridValues(), children: _jsx("div", { className: clsx([
                    'caption-lines',
                    { 'caption-lines--hidden': captionPosition === null },
                ]), ref: captionLinesElementRef, children: caption === null ? null
                    : (caption.lines.map((line) => {
                        var _a, _b, _c, _d;
                        return (_jsx("div", { className: [
                                'caption-line',
                                `caption-line-${String((_b = (_a = caption.format.align) !== null && _a !== void 0 ? _a : defaultHorizontalCaptionPosition) !== null && _b !== void 0 ? _b : 'center')}`,
                                `caption-line-direction-${isRightLoLeft ? 'rtl' : 'ltr'}`,
                                `${grabbing ? 'caption-line-grabbing' : ''}`,
                                `${readOnly ? 'disabled' : ''}`,
                            ].join(' '), style: {
                                fontSize: subtitleFontSize,
                                fontFamily: (_c = captions.parameters) === null || _c === void 0 ? void 0 : _c.defaultFontName,
                                backgroundColor: `rgba(0, 0, 0, ${captionBackgroundOpacity})`,
                                textTransform: ((_d = captions.parameters) === null || _d === void 0 ? void 0 : _d.upperCaseAllText) === true ? 'uppercase' : 'none',
                            }, children: line.map((subline) => (_jsx("span", { style: { color: String(subline.color) }, children: subline.text.replaceAll(' ', '\u00A0') }, nanoid(5)))) }, nanoid(5)));
                    })) }) }) }));
};
export default VideoCaptions;
