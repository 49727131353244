var _a, _b;
import getBlobDuration from 'get-blob-duration';
import { produce, immerable } from 'immer';
import { fetchLinkDetail } from 'api/model/services/services-api';
import { nanoid } from 'nanoid';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
const asyncDelay = (miliseconds, result) => new Promise((resolve) => {
    setTimeout(resolve, miliseconds, result);
});
export class MediaOrigin {
    constructor() {
        this.duration = { status: 'loading', seconds: null };
        this.size = { status: 'loading', size: 0 };
        this.error = null;
    }
    getDurationInCredits() {
        return this.duration.status === 'credits' ? this.duration.credits : null;
    }
    hasShortDuration() {
        const duration = this.getDurationInCredits();
        return duration === null
            ? false
            : duration <= 240;
    }
    hasMediumDuration() {
        const duration = this.getDurationInCredits();
        return duration === null
            ? false
            : duration > 240 && duration <= 480;
    }
    hasLongDuration() {
        const duration = this.getDurationInCredits();
        return duration === null
            ? false
            : duration > 480;
    }
    hasValidDuration() {
        if (this.hasLongDuration()) {
            return false;
        }
        if (this.duration.status === 'loading') {
            return false;
        }
        if (this.duration.status === 'unknown') {
            return true;
        }
        return true;
    }
}
export class MediaFile extends MediaOrigin {
    constructor(file) {
        super();
        this[_a] = true;
        this.resolveDetail = async () => {
            let duration = { status: 'unknown' };
            let size = { status: 'unknown' };
            const originalFile = this.file.originFileObj;
            if (this.getError() !== null) {
                return produce(this, (draft) => {
                    draft.duration = { status: 'unknown' };
                    draft.size = { status: 'unknown' };
                });
            }
            try {
                const seconds = await Promise.race([
                    getBlobDuration(originalFile),
                    asyncDelay(5000, null),
                ]);
                if (seconds !== null) {
                    duration = { status: 'loading', seconds };
                    size = { status: 'loaded', size: originalFile.size };
                }
            }
            catch (error) {
                if (error instanceof MediaError) {
                    global.logger.warn('checking duration failed', { message: error.message, strCode: error.code });
                }
                else {
                    global.logger.error('checking duration failed', {}, error);
                }
            }
            return produce(this, (draft) => {
                draft.duration = duration;
                draft.size = size;
            });
        };
        this.file = file;
    }
    getRawFile() {
        return this.file.originFileObj;
    }
    withCreditsDuration(credits) {
        return produce(this, (draft) => {
            draft.duration = { status: 'credits', credits };
        });
    }
    getError() {
        if (this.file.size === 0) {
            return 'invalid-format';
        }
        const fileFormat = this.file.type;
        if (fileFormat === '') {
            const fileName = this.file.name;
            const fileNameSuffix = fileName.slice(fileName.lastIndexOf('.') + 1);
            return fileNameSuffix === 'flv' ? null : 'invalid-format';
        }
        const fileType = fileFormat === null || fileFormat === void 0 ? void 0 : fileFormat.split('/')[0];
        return (fileType === 'video' || fileType === 'audio') ? null : 'invalid-format';
    }
    getName() {
        return this.file.name.substring(0, this.file.name.lastIndexOf('.'));
    }
}
_a = immerable;
export class MediaUrl extends MediaOrigin {
    constructor(url) {
        super();
        this[_b] = true;
        this.resolveDetail = async (connection) => {
            let duration = { status: 'unknown' };
            let size = { status: 'unknown' };
            try {
                const linkDetail = await fetchLinkDetail(connection, this.url);
                if (linkDetail.duration !== null && linkDetail.duration !== 'NA') {
                    const durationInSeconds = linkDetail.duration.split(':')
                        .map(Number).reduce((accum, currVal) => accum * 60 + currVal);
                    duration = { status: 'loading', seconds: durationInSeconds };
                    size = { status: 'loaded', size: Number(linkDetail.filesizeApprox) };
                    return produce(this, (draft) => {
                        draft.duration = duration;
                        draft.size = size;
                        draft.name = linkDetail.title;
                    });
                }
                return this.produceWithError('invalid-format', duration, size);
            }
            catch (error) {
                if (error instanceof ApiError) {
                    if (error.response.status === 422) {
                        return this.produceWithError('invalid-format', duration, size);
                    }
                    if (error.response.status === 502 || error.response.status === 503) {
                        return this.produceWithError('not-available', duration, size);
                    }
                }
            }
            return this.produceWithError('cannot-process', duration, size);
        };
        this.url = url;
        this.name = this.url;
        this.id = nanoid();
    }
    withCreditsDuration(credits) {
        return produce(this, (draft) => {
            draft.duration = { status: 'credits', credits };
        });
    }
    getError() {
        return this.error;
    }
    getName() {
        return this.name;
    }
    getId() {
        return this.id;
    }
    produceWithError(error, duration, size) {
        return produce(this, (draft) => {
            draft.error = error;
            draft.duration = duration;
            draft.size = size;
        });
    }
}
_b = immerable;
