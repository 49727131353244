import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import css from './style.module.less';
const IconWithTooltip = ({ tooltip, IconComponent, iconSpin, children, }) => {
    if (children !== undefined) {
        return (_jsx(Tooltip, { title: tooltip, placement: "top", children: children }));
    }
    if (IconComponent !== undefined) {
        // Custom made icons have to be wrapped in a span for tooltips to work
        return (_jsx(Tooltip, { title: tooltip, placement: "top", children: _jsx("span", { children: _jsx(IconComponent, { spin: iconSpin, className: css.projectIcon }) }) }));
    }
    return null;
};
export default IconWithTooltip;
