import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ConfigProvider, DatePicker, Input, Modal, Button, Tabs, Form, App, Radio, Flex, Select, } from 'antd';
import dayjs from 'dayjs';
import * as Announcements from 'api/announcements';
import { useSession } from 'components/Authenticated';
import { renderMarkdown } from 'libs/markdown';
import { getCurrentAntdLocale } from 'libs/dayjs-locale';
import { getUiLocales, getCurrentUiLocale } from 'libs/locales/ui';
import { sortedLocales, txt } from 'libs/i18n';
import styles from './style.module.less';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const moveUILangToStart = (locales) => {
    const uiLangIndex = locales.findIndex((locale) => locale.equals(getCurrentUiLocale()));
    const uiLang = locales[uiLangIndex];
    return [
        uiLang,
        ...locales.slice(0, uiLangIndex),
        ...locales.slice(uiLangIndex + 1, locales.length),
    ];
};
const NewAnnouncementModal = ({ onCancel, onOk, visible, teamsOptions, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [form] = Form.useForm();
    const locales = moveUILangToStart(sortedLocales(getUiLocales()));
    const displayTo = Form.useWatch('displayTo', form);
    const saveAnnouncement = async (values) => {
        const teamIds = form.getFieldValue('teams');
        try {
            if (values.datePicker === undefined) {
                void message.error(txt('failedToSave'));
                return;
            }
            const from = values.datePicker[0];
            const to = values.datePicker[1];
            const countryCodes = locales.map((locale) => locale.code);
            const announcements = Object.entries(values).reduce((acc, [key, value]) => {
                if (countryCodes.includes(key)) {
                    return Object.assign(Object.assign({}, acc), { [key]: value });
                }
                return acc;
            }, {});
            await Announcements.saveAnnouncement(session.connection, announcements, teamIds, String(from), String(to));
            onOk();
        }
        catch (error) {
            global.logger.error('Announcement failed to save.', {}, error);
            void message.error(txt('failedToSave'));
        }
        void message.success(txt('announcementSaved'));
        onCancel();
        form.resetFields();
    };
    return (_jsx(Modal, { open: visible, onCancel: onCancel, className: styles.modal, destroyOnClose: true, footer: [
            _jsx(Button, { onClick: onCancel, children: txt('cancel') }, "back"),
            _jsx(Button, { onClick: () => form.submit(), htmlType: "submit", type: "primary", children: txt('okAnnouncement') }, "submit")
        ], children: _jsxs(Form, { form: form, onFinish: saveAnnouncement, initialValues: { datePicker: [dayjs()], displayTo: 'everyone' }, children: [_jsx("h3", { children: txt('titleNewAnnoun') }), _jsx(ConfigProvider, { locale: getCurrentAntdLocale(), children: _jsx(Form.Item, { name: "datePicker", rules: [
                            {
                                validator: async (_, date) => {
                                    if (date === null || date.length === 1) {
                                        return Promise.reject(txt('enterDateAnnouncementRange'));
                                    }
                                    if (date[1].diff(date[0], 'minute') < 1) {
                                        return Promise.reject(txt('minTimeToDisplay'));
                                    }
                                    if (!(dayjs().isBefore(date[0], 'minutes') || dayjs().isSame(date[0], 'minutes'))) {
                                        return Promise.reject(txt('pastTime'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ], children: _jsx(RangePicker, { allowClear: true, placeholder: [txt('startDate'), txt('endDate')], format: ['DD. MM. YYYY HH:mm', 'DD. MM. YYYY HH:mm'], showTime: {
                                format: 'HH:mm',
                                defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59', 'HH:mm')],
                            }, presets: {
                                [txt('thisYear')]: [dayjs(), dayjs().endOf('year')],
                                [txt('thisMonth')]: [dayjs(), dayjs().endOf('month')],
                                [txt('thisWeek')]: [dayjs(), dayjs().endOf('week')],
                                [txt('today')]: [dayjs(), dayjs().endOf('day')],
                            } }) }) }), _jsx(Form.Item, { name: "announValidatorMsg", className: styles.validatorMessage, rules: [
                        ({ getFieldValue }) => ({
                            validator() {
                                // Checks if there is at least one textarea that contains some text,
                                // i.e. !== undefined for empty textarea and !== '' for a cleared textarea
                                if (locales.some((item) => getFieldValue(item.code) !== undefined && getFieldValue(item.code) !== '')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(txt('minimumOneAnnoun'));
                            },
                        }),
                    ], children: _jsx(Input, { type: "hidden" }) }), _jsx(Form.Item, { label: txt('displayTo'), name: "displayTo", children: _jsx(Radio.Group, { children: _jsxs(Flex, { vertical: true, children: [_jsx(Radio, { value: "everyone", children: txt('everyone') }), _jsx(Radio, { value: "team", children: txt('certainTeam') })] }) }) }), displayTo === 'team' ? (_jsx(Form.Item, { label: txt('chooseTeam'), name: "teams", children: _jsx(Select, { options: teamsOptions !== 'loading' ? teamsOptions : [], mode: "multiple", allowClear: true }) })) : null, locales.map((locale) => (_jsxs(_Fragment, { children: [_jsxs("span", { children: [locale.country, ":"] }, locale.country), _jsx(Tabs, { defaultActiveKey: "1", className: styles.tabs, items: [
                                {
                                    key: '1',
                                    label: 'Markdown',
                                    children: (_jsx(Form.Item, { name: locale.code, children: _jsx(TextArea, { className: styles.announcField, rows: 2 }) }, locale.code)),
                                },
                                {
                                    key: '2',
                                    label: txt('preview'),
                                    children: (_jsx(Form.Item, { dependencies: [locale.code], children: ({ getFieldValue }) => {
                                            const content = getFieldValue(locale.code);
                                            const result = renderMarkdown(content === undefined ? '' : content);
                                            return (
                                            // eslint-disable-next-line react/no-danger
                                            _jsx("span", { dangerouslySetInnerHTML: { __html: result } }));
                                        } })),
                                },
                            ] }, locale.code)] })))] }) }));
};
export default NewAnnouncementModal;
