import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as ProjectAPI from 'api/project-api';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { Alert, Button, Collapse, Form, InputNumber, Select, Spin, Switch, Tooltip, App, } from 'antd';
import FormModal from 'components/FormModal';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { txt } from 'libs/i18n';
import { BeeyLocale } from 'libs/locales/locale';
import { SubtitlesFormatSchema } from 'api/settings/user-settings';
import { downloadBlobAsFile } from '../exports';
import { SUBTITLE_LENGTH, AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS } from '../CaptionReviewModal';
import './style.less';
const VTT_TAG_REGEX = /<\/?c\.?[a-z]*>/g; // matches <c.yellow> and </c>
const EMPTY_VTT_CAPTION_REGEX = /\r\n[^\r\n]* --> [^\r\n]*\r\n\r\n/g;
const FORMATED_VTT_REGEX = /STYLE\s*::cue\s*{[^}]*}\r\n\r\n/g;
const SUBTITLE_POSSITION_VTT_REGEX = / align:[^\n\r]* line:[^\r\n]*\r\n/g;
const { Option } = Select;
const SubtitleModal = ({ visible, onCancel, enqueueProjectUpdate, isCaptionMode, captions, }) => {
    var _a;
    const { session, updateUserSettings } = useSession();
    const savedSettings = session.login.user.settings.subtitlesExport;
    const { tutorialContinue, tutorialGoNext, retrieveTutorialState } = useBeeyTutorial();
    const [form] = Form.useForm();
    const [downloading, setDownLoading] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const { message } = App.useApp();
    const isTutorialRunning = retrieveTutorialState() === 'running';
    const selectInitialValue = () => {
        if (session.login.hasClaim('subtitles:all')) {
            return SubtitlesFormatSchema.values[0];
        }
        return SubtitlesFormatSchema.values.find((format) => session.login.hasClaim(`subtitles:${format}`));
    };
    const initialFormat = session.login.hasClaim(`subtitles:${savedSettings.subtitlesFormat}`)
        ? savedSettings.subtitlesFormat
        : selectInitialValue();
    const initialValues = Object.assign(Object.assign({}, savedSettings), { subtitlesFormat: initialFormat });
    const createInfoMessage = (subtitlesFormat) => {
        const formatNotSupportingColoring = 'stl';
        const formatNotSupportingPositioning = 'ttml';
        const formatNotSupportingColorAndPosition = 'srt';
        const isSomeSpeakerColored = captions.editorController.speakers.documentSpeakers.some((speaker) => speaker.isDefaultColor === false);
        if (isSomeSpeakerColored && subtitlesFormat === formatNotSupportingColoring) {
            return `${txt('format')} ${formatNotSupportingColoring} ${txt('unsupportedForColoredCaptions')}`;
        }
        if (captions.isAtLeastACaptionFormated()
            && subtitlesFormat === formatNotSupportingPositioning) {
            return txt('subtitlePositionNotSupported');
        }
        if (subtitlesFormat === formatNotSupportingColorAndPosition) {
            return txt('subtitleFormatting');
        }
        return null;
    };
    const updateAdditionalInfo = () => {
        var _a;
        if (!isCaptionMode) {
            return;
        }
        setAdditionalInfo(createInfoMessage((_a = form.getFieldValue('subtformat')) !== null && _a !== void 0 ? _a : initialValues.subtitlesFormat));
    };
    useEffect(() => {
        if (visible === true) {
            tutorialContinue();
            void updateAdditionalInfo();
        }
    }, [visible]);
    const allSubtitlesAllowed = session.login.hasClaim('subtitles:all');
    const subtitleLength = (_a = captions.parameters) === null || _a === void 0 ? void 0 : _a.maxLineLength;
    const findDifference = (frontendVTT, backendVTT) => {
        let firstDifferenceIndex = null;
        for (let i = 0; i < Math.min(frontendVTT.length, backendVTT.length); i += 1) {
            if (frontendVTT[i] !== backendVTT[i]) {
                firstDifferenceIndex = i;
                break;
            }
        }
        if (firstDifferenceIndex === null) {
            firstDifferenceIndex = Math.min(frontendVTT.length, backendVTT.length);
        }
        return {
            frontend: frontendVTT.substring(firstDifferenceIndex - 50, firstDifferenceIndex + 150),
            backend: backendVTT.substring(firstDifferenceIndex - 50, firstDifferenceIndex + 150),
        };
    };
    const compareVTTwithPreview = async (backendVTTBlob) => {
        const frontendVTT = captions.generateVTT()
            .replace(EMPTY_VTT_CAPTION_REGEX, '');
        const modifiedFrontendVTT = captions.parameters !== null && captions.parameters.upperCaseAllText
            ? frontendVTT.toUpperCase() : frontendVTT;
        const backendVTT = await backendVTTBlob.text();
        const cleanedBackendVTT = backendVTT
            .replaceAll(VTT_TAG_REGEX, '')
            .replaceAll(SUBTITLE_POSSITION_VTT_REGEX, '\r\n')
            .replaceAll(EMPTY_VTT_CAPTION_REGEX, '')
            .replaceAll(FORMATED_VTT_REGEX, '');
        if (modifiedFrontendVTT !== cleanedBackendVTT) {
            global.logger.error('frontend and backend subtitles differ');
            const differenceReport = findDifference(frontendVTT, cleanedBackendVTT);
            global.logger.error('VTT generated by backend differs from preview.', differenceReport);
            return differenceReport.frontend;
        }
        global.logger.info('frontend and backend subtitles match');
        return null;
    };
    const downloadSubtitles = async (formValues, project) => {
        var _a;
        const { maxLineLength, subtitlesFormat, speakerSignPlacement, pauseBetweenCaptions, autofillPauseBetweenCaptionsMs, formatting, upperCaseAllText, } = formValues;
        const language = (_a = await ProjectAPI.fetchProjectLanguage(session.connection, project)) !== null && _a !== void 0 ? _a : BeeyLocale.const('en-US');
        const subtitleFormat = subtitlesFormat === 'srt_FB' ? 'srt' : subtitlesFormat;
        // FB srt format: https://www.facebook.com/help/www/1528795707381162?helpref=faq_content
        const subtitlesName = subtitlesFormat === 'srt_FB' ? `${project.name}.${language.code.replace('-', '_')}` : project.name;
        const isRightToLeft = language.isRightToLeft();
        try {
            setDownLoading(true);
            const blob = (isCaptionMode && captions.parameters !== null)
                ? await ProjectAPI.downloadSubtitlesFromLabeled(session.connection, project, subtitleFormat, formatting ? 'AllowAll' : 'DisableAll', captions.parameters.upperCaseAllText, captions.parameters.keepInnerLinesStripped, isRightToLeft)
                : await ProjectAPI.downloadSubtitles(session.connection, project, subtitleFormat, maxLineLength, speakerSignPlacement, pauseBetweenCaptions, autofillPauseBetweenCaptionsMs / 1000, // in seconds
                upperCaseAllText, isRightToLeft);
            if (isCaptionMode && subtitlesFormat === 'vtt') {
                const maybeErrorReport = await compareVTTwithPreview(blob);
                if (maybeErrorReport !== null) {
                    // eslint-disable-next-line no-restricted-globals, no-alert
                    if (!confirm(`${txt('subtitlesAndPreviewDiffer')}\n ${maybeErrorReport}`)) {
                        setDownLoading(false);
                        onCancel();
                        return;
                    }
                }
            }
            downloadBlobAsFile(`${subtitlesName}.${subtitleFormat}`, blob);
            setDownLoading(false);
            updateUserSettings({
                subtitlesExport: {
                    maxLineLength,
                    speakerSignPlacement,
                    pauseBetweenCaptions,
                    autofillPauseBetweenCaptionsMs,
                    subtitlesFormat,
                    upperCaseAllText,
                    formatting,
                },
            });
            onCancel();
        }
        catch (error) {
            if (error instanceof ApiError && error.response.status === 422) {
                if (error.errorMessage === 'Subtitle line length must be greater or equal 30.') {
                    void message.error(`${txt('minSubtitleLineLength')} ${SUBTITLE_LENGTH.min}`);
                    global.logger.error('Subtitle line length must be greater or equal 30.', {}, error);
                }
                else if (error.errorMessage === 'Subtitle line length must be less or equal 50.') {
                    void message.error(`${txt('maxSubtitleLineLength')} ${SUBTITLE_LENGTH.max}`);
                    global.logger.error('Subtitle line length must be less or equal 50.', {}, error);
                }
                else if (error.errorMessage === 'Subtitle line length must be a positive integer.') {
                    void message.error(txt('integerSubtitleLineLength'));
                    global.logger.error('Subtitle line length must be a positive integer.', {}, error);
                }
                else if (error.errorMessage.includes('Invalid file format')) {
                    void message.error(txt('invalidExportFormat'));
                    global.logger.error('Invalid file format.', {}, error);
                }
                else {
                    void message.error(error.errorMessage);
                    global.logger.error('Validation of input value failed.', {}, error);
                }
            }
            else {
                void message.error(txt('subtitleExportMessageError'));
                global.logger.error('Failed to generate subtitles for unhandled reason', {}, error);
                throw error;
            }
        }
    };
    const handleConfirm = async (formValues) => {
        await enqueueProjectUpdate(async (initialProject) => {
            await downloadSubtitles(formValues, initialProject);
            return initialProject;
        });
    };
    const onModalOk = () => {
        form.submit();
        tutorialGoNext();
    };
    return (_jsxs(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], title: isCaptionMode
            ? txt('subtitleRevisedHead')
            : txt('subtitleAutomaticHead'), className: "subtitle-format-modal beey-tutorial-step-format", open: visible, footer: [
            _jsx(Button, { type: "default", onClick: onCancel, disabled: isTutorialRunning, children: txt('cancel') }, "back"),
            _jsx(Button, { type: "primary", htmlType: "submit", onClick: onModalOk, disabled: downloading, children: _jsxs("div", { className: "subtitle-format-modal__generate-button", children: [downloading && (_jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) })), txt('download')] }) }, "submit"),
        ], onCancel: onCancel, width: 560, destroyOnClose: true, closable: !isTutorialRunning, keyboard: !isTutorialRunning, children: [_jsx("p", { children: isCaptionMode
                    ? txt('subtitleRevised')
                    : txt('subtitleAutomatic') }), _jsxs(Form, { form: form, initialValues: initialValues, requiredMark: false, onFinish: handleConfirm, onValuesChange: updateAdditionalInfo, labelAlign: "left", labelWrap: true, labelCol: { span: 12 }, colon: false, children: [isCaptionMode ? (_jsx(Form.Item, { label: `${txt('selectedSubtitleLength')}:`, children: _jsx("span", { children: `${subtitleLength !== null && subtitleLength !== void 0 ? subtitleLength : -1} ${txt('charactersPerLine')}` }) })) : (_jsxs(Form.Item, { label: `${txt('chooseLineLength')}:`, children: [_jsx(Form.Item, { name: "maxLineLength", noStyle: true, required: true, rules: [
                                    {
                                        required: true,
                                        type: 'number',
                                        min: SUBTITLE_LENGTH.min,
                                        max: SUBTITLE_LENGTH.max,
                                        message: `${txt('range')} ${SUBTITLE_LENGTH.min} - ${SUBTITLE_LENGTH.max}`,
                                    },
                                ], children: _jsx(InputNumber, { type: "number", autoFocus: true }) }), ' ', txt('charactersPerLine')] })), _jsx(Form.Item, { name: "subtitlesFormat", label: txt('chooseSubtFormat'), children: _jsx(Select, { autoFocus: isCaptionMode, onChange: (format) => form.setFieldValue('formatting', format !== 'srt_FB'), children: allSubtitlesAllowed ? (SubtitlesFormatSchema.values.map((format) => (_jsx(Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format))))
                                : (SubtitlesFormatSchema.values
                                    .filter((format) => session.login.hasClaim(`subtitles:${format.split('_')[0]}`))
                                    .map((format) => (_jsx(Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format)))) }) }), !isCaptionMode ? (_jsx(Form.Item, { name: "upperCaseAllText", label: txt('setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { size: "small" }) })) : null, isCaptionMode ? (_jsx(Form.Item, { noStyle: true, dependencies: ['subtitlesFormat'], children: ({ getFieldValue }) => {
                            return (_jsx(Form.Item, { name: "formatting", label: txt('includeColorAndPosition'), valuePropName: "checked", tooltip: getFieldValue('subtitlesFormat') === 'srt_FB' ? txt('noFormatting') : null, children: _jsx(Switch, { disabled: getFieldValue('subtitlesFormat') === 'srt_FB', size: "small" }) }));
                        } })) : null, !isCaptionMode ? (_jsx(Tooltip, { title: isTutorialRunning ? txt('disabledDuringTutorial') : null, placement: "left", children: _jsx(Collapse, { bordered: false, ghost: true, className: "advanced-settings", collapsible: isTutorialRunning ? 'disabled' : undefined, items: [{
                                    key: '1',
                                    label: txt('advancedSettings'),
                                    // NOTE: Collapse has to be forceRendered to enable
                                    // access to its form item initial values when closed.
                                    forceRender: true,
                                    children: (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "speakerSignPlacement", label: txt('speakerSignPlacement'), children: _jsx(Select, { options: [
                                                        {
                                                            value: 'utteranceStartOnly',
                                                            label: txt('utteranceStartOnly'),
                                                        },
                                                        {
                                                            value: 'multiSpeakerCaptionsOnly',
                                                            label: txt('multiSpeakerCaptionsOnly'),
                                                        },
                                                        {
                                                            value: 'none',
                                                            label: txt('noPlacement'),
                                                        },
                                                    ] }) }), _jsx(Form.Item, { name: "pauseBetweenCaptions", label: txt('pauseBetweenCaptions'), children: _jsx(Select, { options: [
                                                        {
                                                            value: 0,
                                                            label: '0 ms',
                                                        },
                                                        {
                                                            value: 0.08,
                                                            label: '80 ms',
                                                        },
                                                    ] }) }), _jsxs(Form.Item, { label: txt('autofillPauseBetweenCaptions'), tooltip: {
                                                    title: `${txt('autofillPauseBetweenCaptionsInfo')} ${txt('range')}\u00A0${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min}\u00A0-\u00A0${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max}`,
                                                    icon: _jsx(InfoCircleOutlined, {}),
                                                }, children: [_jsx(Form.Item, { name: "autofillPauseBetweenCaptionsMs", noStyle: true, rules: [
                                                            {
                                                                type: 'number',
                                                                min: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min,
                                                                max: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max,
                                                                message: `${txt('range')} ${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min} - ${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max} ms`,
                                                            },
                                                        ], children: _jsx(InputNumber, { precision: 0, step: 10 }) }), ' ms'] })] })),
                                }] }) })) : null, (isCaptionMode && additionalInfo !== null) ? (_jsx(Alert, { className: "format-alert", type: "info", showIcon: true, message: additionalInfo })) : null] })] }));
};
export default SubtitleModal;
