import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Layout, Drawer, Menu, Button, Input, } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined, DownloadOutlined, FormOutlined, SettingOutlined, CommentOutlined, PlayCircleOutlined, FileWordOutlined, FilePdfOutlined, AudioOutlined, FireOutlined, TagsOutlined, MoreOutlined, } from '@ant-design/icons';
import ResponsiveTooltip from 'components/ResponsiveTooltip';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import { TranslateIcon } from 'components/CustomIcons';
import { buildMediaFileUrl, fetchExport, fetchProjectMetadata, } from 'api/project-api';
import TagsPanel from 'components/TagsPanel';
import { useSession } from 'components/Authenticated';
import { fetchExportTemplates } from 'api/team-api';
import { SubtitlesFormatSchema } from 'api/settings/user-settings';
import './style.less';
import '../../ProjectsPage/TagsFilter/style.less';
import ControlsButton from './ControlsButton';
import { disabledGuards } from './disabledGuards';
import ProjectMenuAdvanced from './ProjectMenuAdvanced';
import { useSavingStatus } from '../DocumentEditor/saving-state-handler';
import { useSubModeTutorial } from '../subModeTutorial';
import { downloadBlobAsFile, downloadUrl } from './exports';
import ProjectMenuModals from './ProjectMenuModals';
const { Sider } = Layout;
const { TextArea } = Input;
const ProjectMenuPanel = ({ project, mediaProcessingStatus, projectErrorStatus, mediaSource, isCaptionMode, editorController, isWaveVisible, onWaveVisibilityChange, enqueueProjectUpdate, }) => {
    var _a, _b, _c, _d;
    const [translateModalVisible, setTranslateModalVisible] = useState(false);
    const [captionReviewModalVisible, setCaptionReviewModalVisible] = useState(false);
    const [subtitleModalVisible, setSubtitleModalVisible] = useState(false);
    const [burnSubtitlesModalVisible, setBurnSubtitlesModalVisible] = useState(false);
    const [cutMediaModalVisible, setCutMediaModalVisible] = useState(false);
    const [projNotesVisible, setProjNotesVisible] = useState(false);
    const [tagsModalVisible, setTagsModalVisible] = useState(false);
    const [downloadModal, setDownloadModal] = useState({ visible: false, format: 'pdf' });
    const [notes, setNotes] = useState('');
    const { screenSize } = useAppContext();
    const { retrieveTutorialState } = useSubModeTutorial();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exportTemplates, setExportTemplates] = useState([]);
    const isMobile = screenSize.width < screenBreakpoints.md;
    const { session, updateUserSettings } = useSession();
    const { settings } = session.login.user;
    const beeyTutorialContext = useBeeyTutorial();
    const [userCollapsed, setUserCollapsed] = useState(isMobile || clientEnv.getIsMenuCollapsed());
    const [submenuItemsOpen, setSubmenuItemsOpen] = useState(clientEnv.getProjectSidemenuItemsOpen());
    const collapsed = userCollapsed || retrieveTutorialState() === 'running' || beeyTutorialContext.retrieveTutorialState() === 'running';
    const savingStatus = useSavingStatus();
    const subModeTutorialContext = useSubModeTutorial();
    const isSaving = savingStatus === 'saving' || savingStatus === 'retrying';
    const isRightToLeft = (_b = (_a = editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.isRightToLeft()) !== null && _b !== void 0 ? _b : false;
    const disabledChecks = {
        projectSaved: isSaving ? txt('waitUntilSaved') : null,
        readOnly: project.isReadOnly ? txt('readOnlyProject') : null,
        mediaProcessingComplete: mediaProcessingStatus === 'completed' ? null : txt('preCompleted'),
        successProject: projectErrorStatus !== 'no-error' ? txt('projectUnavailable') : null,
        currectTrsxAvailable: project.currentTrsxId === null ? txt('noTranscription') : null,
        mediaReady: mediaSource.isPackaged() ? null : txt('preMediaReady'),
        subtitlesExportAllowed: (session.login.hasClaim('subtitles:all')
            || SubtitlesFormatSchema.values.some((format) => session.login.hasClaim(`subtitles:${format}`))
            ? null
            : txt('subtitlesExportForbidden')),
        editorHasContent: (mediaProcessingStatus === 'words-arriving'
            || mediaProcessingStatus === 'completed'
            ? null
            : txt('noTranscription')),
    };
    const prepareSubModeTutorial = () => {
        updateUserSettings({ startSubModeTutorial: true });
    };
    const handleDownloadBlob = async (format) => {
        await enqueueProjectUpdate(async (initialProject) => {
            const blob = await fetchExport(session.connection, project, format, settings.enableTimestampLabels, isRightToLeft);
            downloadBlobAsFile(`${project.name}.${format}`, blob);
            return initialProject;
        });
    };
    const handleDownloadMedia = async () => {
        const url = await buildMediaFileUrl(project, session.connection);
        downloadUrl(url, `${project.name}.mp4`);
    };
    const loadProjNotes = async (projectId) => {
        const fetchedProjNotes = await fetchProjectMetadata(session.connection, projectId, 'notes');
        setNotes(fetchedProjNotes !== null && fetchedProjNotes !== void 0 ? fetchedProjNotes : '');
    };
    const fetchExportTemplatesState = async () => {
        const templates = await fetchExportTemplates(session.connection);
        setExportTemplates(templates);
    };
    useEffect(() => {
        beeyTutorialContext.tutorialContinue();
    }, [beeyTutorialContext.currentStep]);
    useEffect(() => {
        void loadProjNotes(project.id);
        void fetchExportTemplatesState();
    }, []);
    const handleMenuCollapse = (collapse) => {
        setUserCollapsed(collapse);
        clientEnv.setIsMenuCollapsed(collapse);
    };
    const items = [
        session.login.hasClaim('editor:subtitleMode') ? {
            key: 'subtitleMode',
            icon: _jsx(CommentOutlined, { className: "sub-mode-tutorial-step-settings" }),
            label: txt('subtitleMode'),
            children: [{
                    key: 'subtitles',
                    label: (_jsx(ControlsButton, { icon: isCaptionMode ? _jsx(SettingOutlined, {}) : _jsx(CommentOutlined, {}), label: isCaptionMode ? txt('subtitleSettings') : txt('convertToSubtitles'), action: () => {
                            setCaptionReviewModalVisible(true);
                            subModeTutorialContext.tutorialPrepareNext();
                        }, disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.successProject,
                            disabledChecks.readOnly,
                        ]), className: `sub-mode-tutorial-step-choose-settings ${collapsed ? 'controls-button__btn-dropdown' : ''}` })),
                    className: 'submenu',
                },
                (isCaptionMode && screenSize.width > screenBreakpoints.lg) && {
                    label: (_jsx(ControlsButton, { icon: _jsx(PlayCircleOutlined, {}), label: txt('runTutorial'), action: prepareSubModeTutorial, className: clsx({ 'controls-button__btn-dropdown': collapsed }), disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.successProject,
                            disabledChecks.readOnly,
                        ]) })),
                    key: 'tutorial',
                    className: 'submenu',
                },
            ],
        } : null,
        session.login.hasClaim('services:translate')
            ? {
                key: 'translate',
                label: 'translate',
                icon: _jsx(TranslateIcon, { className: "translate-icon" }),
                children: [{
                        label: (_jsx(ControlsButton, { icon: _jsx(TranslateIcon, {}), label: txt('smartTranslation'), action: () => setTranslateModalVisible(true), disabledMessage: disabledGuards([
                                disabledChecks.mediaProcessingComplete,
                                disabledChecks.successProject,
                            ]), className: clsx({ 'controls-button__btn-dropdown': collapsed }) })),
                        key: 'smartTranslation',
                        className: 'submenu',
                    },
                ],
            } : null,
        {
            key: 'download',
            label: txt('download'),
            icon: _jsx(DownloadOutlined, { className: "beey-tutorial-step-subtitles" }),
            children: [{
                    label: (_jsx(ControlsButton, { icon: _jsx(FileWordOutlined, {}), label: txt('downloadDoc'), busyMessage: `${txt('downloading')}…`, action: exportTemplates.length > 0
                            ? () => setDownloadModal({ visible: true, format: 'docx' })
                            : undefined, asyncAction: exportTemplates.length > 0
                            ? undefined
                            : () => handleDownloadBlob('docx'), disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                        ]), className: clsx({ 'controls-button__btn-dropdown': collapsed }) })),
                    key: 'downloadDoc',
                    className: 'submenu',
                },
                {
                    label: (_jsx(ControlsButton, { icon: _jsx(FilePdfOutlined, {}), label: txt('downloadPdf'), busyMessage: `${txt('downloading')}…`, action: exportTemplates.length > 0
                            ? () => setDownloadModal({ visible: true, format: 'pdf' })
                            : undefined, asyncAction: exportTemplates.length > 0
                            ? undefined
                            : () => handleDownloadBlob('pdf'), disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                        ]), className: clsx({ 'controls-button__btn-dropdown': collapsed }) })),
                    key: 'downloadPdf',
                    className: 'submenu',
                },
                {
                    label: (_jsx(ControlsButton, { icon: _jsx(AudioOutlined, {}), label: txt('dlMedia'), action: session.login.hasClaim('services:transcode')
                            ? () => setCutMediaModalVisible(true)
                            : handleDownloadMedia, disabledMessage: disabledGuards([
                            disabledChecks.successProject,
                            disabledChecks.mediaReady,
                        ]), className: clsx({ 'controls-button__btn-dropdown': collapsed }) })),
                    key: 'downloadMedia',
                    className: 'submenu',
                },
                ((_d = (_c = project.mediaInfo) === null || _c === void 0 ? void 0 : _c.hasVideo) !== null && _d !== void 0 ? _d : false)
                    && {
                        label: (_jsx(ControlsButton, { icon: _jsx(FireOutlined, {}), label: txt('burnSubtitlesInVideo'), accessClaim: "services:transcode", action: () => {
                                setBurnSubtitlesModalVisible(true);
                            }, disabledMessage: disabledGuards([
                                disabledChecks.mediaProcessingComplete,
                                disabledChecks.subtitlesExportAllowed,
                            ]), className: clsx({ 'controls-button__btn-dropdown': collapsed }) })),
                        key: 'burnSubtitles',
                        className: 'submenu',
                    },
                {
                    label: (_jsx(ControlsButton, { className: clsx({ 'controls-button__btn-dropdown': collapsed }, 'beey-tutorial-step-choose-subs'), icon: _jsx(DownloadOutlined, {}), label: txt('downloadSubtitles'), action: () => {
                            setSubtitleModalVisible(true);
                            beeyTutorialContext.tutorialPrepareNext();
                        }, disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.subtitlesExportAllowed,
                        ]) })),
                    key: 'downloadSubtitles',
                    className: 'submenu',
                },
            ],
        },
        {
            key: 'notes',
            label: txt('notes'),
            icon: _jsx(FormOutlined, {}),
            children: [
                {
                    label: notes ? (_jsxs("div", { className: "notes-controls", children: [_jsxs("div", { className: "notes-controls__header", children: [collapsed ? (_jsxs("div", { className: "project-menu__notes-title", children: [_jsx(FormOutlined, {}), _jsx("p", { children: txt('notes') })] })) : null, _jsx(Button, { id: "btn-edit-notes", onClick: () => setProjNotesVisible(true), type: "primary", className: "submenu-button", children: txt('editVerb') })] }), _jsx(TextArea, { disabled: true, className: "project-menu__notes-content", rows: 7, value: notes })] })) : (_jsx(ControlsButton, { icon: _jsx(FormOutlined, {}), label: txt('addNotes'), action: () => setProjNotesVisible(true), className: collapsed ? 'controls-button__btn-dropdown' : '', disabledMessage: disabledGuards([
                            disabledChecks.readOnly,
                        ]) })),
                    key: 'notesEdit',
                    className: 'submenu',
                },
            ],
        },
        {
            key: 'tags',
            label: txt('tags'),
            icon: _jsx(TagsOutlined, {}),
            onClick: () => {
                if (collapsed) {
                    setTagsModalVisible(true);
                }
            },
            children: !collapsed ? [{
                    label: (_jsx("div", { className: "project-menu__tags", children: _jsx("div", { className: "project-menu__tags-section tags-section", children: _jsx(TagsPanel, { project: project, enqueueProjectUpdate: enqueueProjectUpdate }) }) })),
                    className: 'submenu submenu__tags',
                }] : null,
        },
        {
            key: 'advanced',
            label: txt('advancedSettings'),
            icon: _jsx(MoreOutlined, {}),
            children: [{
                    label: (_jsx(ProjectMenuAdvanced, { project: project, editorController: editorController, disabledChecks: disabledChecks, buttonClassName: collapsed ? 'controls-button__btn-dropdown' : '' })),
                    key: 'advancedSettings',
                    className: 'submenu',
                }],
        },
    ];
    const handleOpenChange = (openItems) => {
        if (!collapsed) {
            clientEnv.setMenuItemsOpen(openItems);
            setSubmenuItemsOpen(openItems);
        }
        if (openItems[0] === 'download') {
            beeyTutorialContext.tutorialGoNext();
        }
        if (openItems[0] === 'subtitleMode') {
            subModeTutorialContext.tutorialGoNext();
        }
    };
    const openKeysInTutorial = () => {
        if (beeyTutorialContext.currentStep === 5) {
            return (['download']);
        }
        return undefined;
    };
    return (_jsxs(Sider, { className: clsx('project-menu__sider', { collapsed }), collapsed: collapsed, collapsible: true, collapsedWidth: "3.5rem", width: "250px", onCollapse: () => handleMenuCollapse(!collapsed), trigger: collapsed
            ? (_jsx(ResponsiveTooltip, { title: txt('expandSidebar'), open: tooltipVisible, onOpenChange: (visible) => setTooltipVisible(visible), children: _jsx(DoubleRightOutlined, { onMouseDown: () => setTooltipVisible(false) }) }))
            : (_jsx(ResponsiveTooltip, { title: txt('collapseSidebar'), open: tooltipVisible, onOpenChange: (visible) => setTooltipVisible(visible), children: _jsx(DoubleLeftOutlined, { onMouseDown: () => setTooltipVisible(false) }) })), children: [isMobile ? (_jsx(Drawer, { open: !collapsed, placement: "left", className: "mobile-project-menu-drawer", onClose: () => handleMenuCollapse(true), children: _jsx(Menu, { items: items, mode: "inline", selectable: false, className: "project-menu", openKeys: collapsed ? openKeysInTutorial() : submenuItemsOpen, onOpenChange: handleOpenChange }) })) : null, _jsx(Menu, { items: items, mode: "inline", selectable: false, className: "project-menu", openKeys: collapsed ? openKeysInTutorial() : submenuItemsOpen, onOpenChange: handleOpenChange, triggerSubMenuAction: "click" }), _jsx(ProjectMenuModals, { isCaptionMode: isCaptionMode, project: project, editorController: editorController, isWaveVisible: isWaveVisible, onSetCaptionReviewModalVisible: setCaptionReviewModalVisible, onSetTranslateModalVisible: setTranslateModalVisible, onSetBurnSubtitlesModalVisible: setBurnSubtitlesModalVisible, onSetCutMediaModalVisible: setCutMediaModalVisible, onSetSubtitleModalVisible: setSubtitleModalVisible, onSetProjNotesVisible: setProjNotesVisible, onSetNotes: setNotes, onWaveVisibilityChange: onWaveVisibilityChange, onSetTagsModalVisible: setTagsModalVisible, captionReviewModalVisible: captionReviewModalVisible, translateModalVisible: translateModalVisible, burnSubtitlesModalVisible: burnSubtitlesModalVisible, cutMediaModalVisible: cutMediaModalVisible, subtitleModalVisible: subtitleModalVisible, projNotesVisible: projNotesVisible, tagsModalVisible: tagsModalVisible, notes: notes, enqueueProjectUpdate: enqueueProjectUpdate, downloadModal: downloadModal, exportTemplates: exportTemplates, setDownloadModalVisible: (visible) => setDownloadModal(Object.assign(Object.assign({}, downloadModal), { visible })), isRightToLeft: isRightToLeft })] }));
};
export default ProjectMenuPanel;
