import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TutorialModal from 'components/Tutorial/TutorialModal';
import ProjectNotesModal from 'components/ProjectNotesModal';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { Modal } from 'antd';
import TagsPanel from 'components/TagsPanel';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import SubtitleModal from '../SubtitleModal';
import CutMediaModal from '../CutMediaModal';
import BurnSubtitlesModal from '../BurnSubtitlesModal';
import TranslateModal from '../TranslateModal';
import CaptionReviewModal from '../CaptionReviewModal';
import { useSubModeTutorial } from '../../subModeTutorial';
import DownloadModal from '../../DocumentEditor/DownloadModal';
const ProjectMenuModals = ({ isCaptionMode, project, editorController, isWaveVisible, onSetCaptionReviewModalVisible, onSetTranslateModalVisible, onSetBurnSubtitlesModalVisible, onSetCutMediaModalVisible, onSetSubtitleModalVisible, onSetProjNotesVisible, onSetNotes, onWaveVisibilityChange, onSetTagsModalVisible, captionReviewModalVisible, translateModalVisible, burnSubtitlesModalVisible, cutMediaModalVisible, projNotesVisible, subtitleModalVisible, tagsModalVisible, notes, enqueueProjectUpdate, downloadModal, setDownloadModalVisible, exportTemplates, isRightToLeft, }) => {
    const { session, updateUserSettings } = useSession();
    const { settings } = session.login.user;
    const { screenSize } = useAppContext();
    const subModeTutorialContext = useSubModeTutorial();
    const skipSubModeTutorial = () => {
        updateUserSettings({ startSubModeTutorial: false });
    };
    const runSubModeTutorial = () => {
        updateUserSettings({ startSubModeTutorial: false });
        subModeTutorialContext.tutorialStart();
    };
    return (_jsxs(_Fragment, { children: [_jsx(CaptionReviewModal, { visible: captionReviewModalVisible, onCancel: () => onSetCaptionReviewModalVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, project: project, isCaptionMode: isCaptionMode, editorController: editorController, isWaveVisible: isWaveVisible, onWaveVisibilityChange: onWaveVisibilityChange }), _jsxs(TutorialModal, { visible: settings.startSubModeTutorial && isCaptionMode
                    && screenSize.width > screenBreakpoints.lg, skipTutorial: skipSubModeTutorial, tutorialAction: runSubModeTutorial, tutorialActionLabel: txt('tutorialStart'), skipTutorialLabel: txt('tutorialSkip'), children: [_jsx("h2", { children: txt('subModeTutorialStartHeading') }), _jsx("p", { children: txt('subModeTutorialStartText') })] }), _jsx(TranslateModal, { visible: translateModalVisible, onCancel: () => onSetTranslateModalVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, project: project }), _jsx(BurnSubtitlesModal, { visible: burnSubtitlesModalVisible, onCancel: () => onSetBurnSubtitlesModalVisible(false), project: project, enqueueProjectUpdate: enqueueProjectUpdate, isCaptionMode: isCaptionMode, selectedTextTimestamps: editorController.getSelectionTimeRange(), captionsParameters: editorController.captions.parameters, isRightToLeft: isRightToLeft }), _jsx(CutMediaModal, { visible: cutMediaModalVisible, onCancel: () => onSetCutMediaModalVisible(false), project: project, selectedTextTimestamps: editorController.getSelectionTimeRange() }), _jsx(SubtitleModal, { visible: subtitleModalVisible, onCancel: () => onSetSubtitleModalVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, isCaptionMode: isCaptionMode, captions: editorController.captions }), _jsx(ProjectNotesModal, { visible: projNotesVisible, onCancel: () => onSetProjNotesVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, onNotesChange: onSetNotes, notes: notes }), _jsx(DownloadModal, { downloadFormat: downloadModal.format, visible: downloadModal.visible, setDownloadModalVisible: setDownloadModalVisible, exportTemplates: exportTemplates, enqueueProjectUpdate: enqueueProjectUpdate, isRightToLeft: isRightToLeft }), _jsx(Modal, { title: txt('tags'), open: tagsModalVisible, onCancel: () => onSetTagsModalVisible(false), destroyOnClose: true, footer: false, children: _jsx(TagsPanel, { project: project, enqueueProjectUpdate: enqueueProjectUpdate }) })] }));
};
export default ProjectMenuModals;
