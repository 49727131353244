import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LanguageSelect from 'components/LanguageSelect';
import { sortedLocales } from 'libs/i18n';
import { getCurrentUiLocale, getUiLocales } from 'libs/locales/ui';
import { useAppContext } from 'App/app-context';
import styles from './style.module.less';
import Screen from '../Screen';
import LeadPanel from '../LeadPanel';
const IntroScreen = ({ children = null, redirectURL = null, isLogoDisplayed = true, }) => {
    const { changeUiLocale } = useAppContext();
    const uiLocales = sortedLocales(getUiLocales());
    return (_jsxs(Screen, { intro: true, children: [_jsx("div", { className: styles.hexagons }), _jsx("div", { className: styles.langSelect, children: _jsx(LanguageSelect, { locales: uiLocales, value: getCurrentUiLocale(), onChange: changeUiLocale }) }), _jsx(LeadPanel, { redirectURL: redirectURL, isLogoDisplayed: isLogoDisplayed, children: children })] }));
};
export default IntroScreen;
